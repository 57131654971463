import {
	HomePage,
	ContactsPage,
	SalesPage,
	MenuPage,
	ErrorPage,
	DeliveryPage,
	CartPage,
	SearchPage,
	ProductPage,
	PolicyPage,
} from '@/pages';
import { withSettingsData } from '@/modules/application';
import { PATHS } from './routes-constants';
import { TRoutes } from './routes-types';

export const routes: TRoutes = [
	{
		path: PATHS.HOME_PAGE,
		component: withSettingsData(HomePage),
	},
	{
		path: PATHS.MENU_PAGE,
		component: withSettingsData(MenuPage),
	},
	{
		path: PATHS.CONTACTS_PAGE,
		component: withSettingsData(ContactsPage),
	},
	{
		path: PATHS.SALES_PAGE,
		component: withSettingsData(SalesPage),
	},
	{
		path: PATHS.DELIVERY_PAGE,
		component: withSettingsData(DeliveryPage),
	},
	{
		path: PATHS.CART_PAGE,
		component: withSettingsData(CartPage),
	},
	{
		path: PATHS.SEARCH_PAGE,
		component: withSettingsData(SearchPage),
	},
	{
		path: PATHS.PRODUCT_PAGE,
		component: withSettingsData(ProductPage),
	},
	{
		path: PATHS.POLICY_PAGE,
		component: withSettingsData(PolicyPage),
	},
	{
		path: PATHS.ERROR_PAGE,
		component: ErrorPage,
	},
	{
		path: '*',
		component: ErrorPage,
	},
];
