import { RefObject } from 'react';
import { createSelector } from 'reselect';
import { getRandomFromArray, sortByOrder } from '@/utils';
import { STORE_KEYS } from '@/store/store-constants';
import { DEFAULT_TITLE } from './application-constants';
import {
	TCategories,
	TCategory,
	TStructuredCategories,
	TProduct,
	TProducts,
	TApplicationState,
	TCategoryPositions,
	TSettings,
	ICartState,
	TCartItem,
	TClientDataStorage,
	TTitleEntity,
} from './application-types';

const getApplicationState = (state: TApplicationState) => state[STORE_KEYS.APPLICATION];

export const getCategories = createSelector(
	getApplicationState,
	(
		applicationState: TApplicationState,
	): TCategories => applicationState.categories,
);

export const getProducts = createSelector(
	getApplicationState,
	(
		applicationState: TApplicationState,
	): TProducts => applicationState.products,
);

export const getSettings = createSelector(
	getApplicationState,
	(
		applicationState: TApplicationState,
	): TSettings | {} => applicationState.settings,
);

export const getTitles = createSelector(
	getApplicationState,
	(
		applicationState: TApplicationState,
	): Array<TTitleEntity> => applicationState.titles,
);

export const createGetTitle = (url: string) => createSelector(
	getTitles,
	(
		titles: Array<TTitleEntity>,
	): string => {
		const titleEntity = titles.find((titleEntity: TTitleEntity) => titleEntity.url === url);
		return titleEntity ? titleEntity.title : DEFAULT_TITLE;
	},
);

export const getFooterBackgroundImage = createSelector(
	getSettings,
	(
		settings: TSettings,
	): string => settings.footerBgImage,
);

export const getRememberProductProposals = createSelector(
	getProducts,
	(
		products: TProducts,
	): TProducts => products.filter((productItem: TProduct) => productItem.isRememberProposal),
);

export const getCategoriesPositions = createSelector(
	getApplicationState,
	(
		applicationState: TApplicationState,
	): TCategoryPositions => applicationState.categoriesPositions,
);

export const getSortedCategories = createSelector(
	getCategories,
	(
		categories?: TCategories,
	): TCategories => categories?.sort(sortByOrder),
);

export const getStructuredCategoriesWithProducts = createSelector(
	getSortedCategories,
	getProducts,
	(
		categories?: TCategories,
		products?: TProducts,
	): TStructuredCategories => categories?.map((category: TCategory) => {
		const sortedCategoryProducts: TProducts = products
			?.filter((product: TProduct) => product.categoryId === category.id)
			.sort(sortByOrder);
		return {
			...category,
			products: sortedCategoryProducts,
		};
	}),
);

export const getHeaderReference = createSelector(
	getApplicationState,
	(
		applicationState: TApplicationState,
	): RefObject<any> | {} => applicationState.headerReference,
);

export const getProposalsRandom = createSelector(
	getProducts,
	(products: TProducts): TProducts => {
		return getRandomFromArray(products, 4);
	},
);

export const getProposalsFromFlagRandom = createSelector(
	getProducts,
	(products: TProducts): TProducts => {
		const productsWithFlag = products?.filter((productItem: TProduct) => productItem.isProductCardProposal);
		return getRandomFromArray(productsWithFlag, 4);
	},
);

export const getProposals = createSelector(
	getProposalsRandom,
	getProposalsFromFlagRandom,
	getSettings,
	(
		proposalsRandom: TProducts,
		proposalsFromFlagRandom: TProducts,
		settings: TSettings,
	): TProducts => settings.isCardProposalsRandom ? proposalsRandom : proposalsFromFlagRandom,
);

/* CART SELECTORS*/
const getCartState = (state: ICartState) => state[STORE_KEYS.CART];

export const getCartItems = createSelector(
	getCartState,
	(cartState: ICartState): Array<TCartItem> => cartState.cartItems,
);

export const getCartSummary = createSelector(
	getCartItems,
	(cartItems: Array<TCartItem>): number => cartItems.reduce((acc: number, cartItem: TCartItem) => {
		acc = acc + Number(cartItem.summary);
		return acc;
	}, 0),
);

export const getEmptyCartAttentionText = createSelector(
	getCartState,
	(cartState: ICartState): string => cartState.emptyCartAttentionText,
);

export const getClientData = createSelector(
	getCartState,
	(cartState: ICartState): TClientDataStorage => cartState.clientData,
);

export const getIsOrderProcessing = createSelector(
	getCartState,
	(cartState: ICartState): boolean => cartState.orderProcessing,
);
/* END CART SELECTORS*/
