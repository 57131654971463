import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import { rootNode } from '@/nodes';
import { ISidebarProps } from './sidebar-types';
import styles from './sidebar.module.scss';

export class Sidebar extends React.PureComponent<ISidebarProps> {
	sidebarRef: React.RefObject<any> = React.createRef();

	componentDidUpdate(): void {
		if (this.props.isOpen) {
			document.documentElement.style.overflow = 'hidden';
		} else {
			document.documentElement.style.overflow = 'auto';
		}
	}

	handleClose = () => {
		this.props.closeSidebar();
	};

	clickOver = (event: React.MouseEvent<any>) => {
		if (!this.sidebarRef.current.contains(event.target)) {
			this.handleClose();
		}
	};

	render() {
		const { children, isOpen, className } = this.props;
		return ReactDOM.createPortal((
			<div
				className={cn(
					styles['wrapper'],
					{
						[styles['wrapper--active']]: isOpen,
					},
					className,
				)}
				onClick={this.clickOver}
			>
				<aside
					className={cn(
						styles['container'],
						{
							[styles['container--active']]: isOpen,
						},
					)}
					ref={this.sidebarRef}
				>
					{children}
				</aside>
			</div>
		), rootNode);
	}
}
