import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withProductsData, withTitles } from '@/modules/application';
import { HomePage } from './home-page';
import { IHomePageControllerComponentProps } from './home-page-types';

export class HomePageControllerComponent extends React.PureComponent<IHomePageControllerComponentProps> {
	render() {
		return (
			<HomePage />
		);
	}
}

export const HomePageController = compose(
	withTitles,
	withRouter,
	withProductsData,
)(HomePageControllerComponent);
