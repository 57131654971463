import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
	addCartItemToStorage,
	changeCartItemCount,
	TProduct,
} from '@/modules/application';
import { withModals, PRODUCT_MODAL_NAME } from '@/modules/modals';
import { goToProduct } from '@/utils';
import { getProductItemCountInCart } from './product-selectors';
import { Product } from './product';
import { IProductControllerComponentProps, IProductControllerComponentState } from './product-types';

export class ProductControllerComponent extends React.PureComponent<
	IProductControllerComponentProps,
	IProductControllerComponentState
> {
	productRef: React.RefObject<any> = React.createRef();

	state = {
		elementWidth: 0,
	};

	componentDidMount(): void {
		if (window.innerWidth > 767) {
			this.setState({
				elementWidth: this.productRef.current.offsetWidth,
			});
		}
	}

	addToCart = (event: React.MouseEvent, productItem: TProduct) => {
		event.stopPropagation();
		this.props.addCartItemToStorage(productItem);
	};

	goToProduct = () => {
		const { productItem } = this.props;
		goToProduct(productItem.categorySlug, productItem.slug);
	};

	showProductModal = () => {
		this.props.showModal({
			name: PRODUCT_MODAL_NAME,
			data: {
				id: this.props.productItem?.id,
			},
		});
	};

	render() {
		const {
			productItem,
			productItemCartCount,
			size = 'default',
		} = this.props;
		const { elementWidth } = this.state;

		return (
			<Product
				productItem={productItem}
				elementWidth={elementWidth}
				productItemCartCount={productItemCartCount}
				size={size}
				productRef={this.productRef}
				goToProduct={this.goToProduct}
				addToCart={this.addToCart}
				showProductModal={this.showProductModal}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	productItemCartCount: getProductItemCountInCart,
});

const mapDispatchToProps = {
	changeCartItemCount,
	addCartItemToStorage,
};

export const ProductController = compose(
	withModals,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(ProductControllerComponent);
