import * as React from 'react';
import { ScrollToTop } from './scroll-to-top';
import { SCROLL_HEIGHT_TO_VIEW } from './scroll-to-top-constants';
import { IScrollToTopController } from './scroll-to-top-types';

export class ScrollToTopController extends React.PureComponent<IScrollToTopController> {
	state = {
		isScrollToTopVisibility: false,
	};

	componentDidMount(): void {
		document.addEventListener('scroll', this.onScrollHandler);
	}

	componentWillUnmount(): void {
		document.removeEventListener('scroll', this.onScrollHandler);
	}

	onClickHandler = (): void => window.scrollTo({ top: 0, behavior: 'smooth' });

	onScrollHandler = (): void => {
		if (window.scrollY > SCROLL_HEIGHT_TO_VIEW) {
			this.setState({ isScrollToTopVisibility: true });
		} else {
			this.setState({ isScrollToTopVisibility: false });
		}
	};

	render() {
		return (
			<ScrollToTop
				onClick={this.onClickHandler}
				isScrollToTopVisibility={this.state.isScrollToTopVisibility}
			/>
		);
	}
}