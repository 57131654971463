import * as React from 'react';
import cn from 'classnames';
import ProgressiveImage from 'react-progressive-image';
import { IMAGE_PRELOADER } from '@/modules/product';
import { IBarItemProps } from '../../categories-bar-types';
import styles from '../../categories-bar.module.scss';

export const BarItem = React.memo(({ id, image, name, onCategoryClick, fixed }: IBarItemProps) => (
	<div
		className={styles['category-bar-item']}
		onClick={() => onCategoryClick(id)}
	>
		{image && (
			<ProgressiveImage
				src={image}
				placeholder={IMAGE_PRELOADER}
			>
				{(src, isLoading) => (
					<div
						className={cn(
							styles['category-bar-item-image'],
							{
								[styles['category-bar-item-image--hidden']]: fixed,
								[styles['category-bar-item-image--loading']]: isLoading,
							},
						)}
						style={{ backgroundImage: `url(${src})` }}
					/>
				)}
			</ProgressiveImage>
		)}
		<div className={cn(
			styles['category-bar-item-name'],
			{
				[styles['category-bar-item-name--fixed']]: fixed,
			},
		)}>
			{name}
		</div>
	</div>
));
