import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSettings, withTitles } from '@/modules/application';
import { ContactsPage } from './contacts-page';
import { IContactsPageControllerComponentProps } from './contacts-page-types';

export class ContactsPageControllerComponent extends React.PureComponent<IContactsPageControllerComponentProps> {
	render() {
		const {
			settings: {
				phone1,
				phone2,
				email,
				workStartTime,
				workEndTime,
				address,
				latitude,
				longitude,
				mapBaloonContent,
			},
		} = this.props;

		return (
			<ContactsPage
				phone1={phone1}
				phone2={phone2}
				email={email}
				address={address}
				workStartTime={workStartTime}
				workEndTime={workEndTime}
				latitude={latitude}
				longitude={longitude}
				mapBaloonContent={mapBaloonContent}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	settings: getSettings,
});

const connector = connect(mapStateToProps);

export const ContactsPageController = compose(
	withTitles,
	withRouter,
	connector,
)(ContactsPageControllerComponent);