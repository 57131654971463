import * as React from 'react';
import { history } from '@/history';
import { SearchPresenter } from './search-presenter';
import { ISearchPresenterController } from './search-presenter-types';

export class SearchPresenterController extends React.PureComponent<ISearchPresenterController> {
	onClickHandler = () => {
		const { redirectTo, closeSidebar } = this.props;
		if (closeSidebar) {
			closeSidebar();
		}
		if (redirectTo) {
			history.push(redirectTo);
		}
		return;
	};

	get isMatchedUrl() {
		return location.pathname.includes('search');
	}

	render() {
		return (
			<SearchPresenter
				onClick={this.onClickHandler}
				isActive={this.isMatchedUrl}
				className={this.props.className}
			/>
		);
	}
}