import * as React from 'react';
import cn from 'classnames';
import { IRowProps } from './aline-types';

export const Row = ({
	children,
	className,
	noStretch,
	center,
	textCenter,
	alignCenter,
}: IRowProps) => {
	const rowClass = noStretch ? 'c-r' : 'r';
	// TODO (perspective aline-ui) посмотреть в класснамес может можно проще
	return (
		<div className={cn(
			rowClass,
			className,
			{
				'jc-c': center,
				't-c': textCenter,
				'ai-c': alignCenter,
			},
		)}>
			{children}
		</div>
	);
};
