import * as React from 'react';
import cn from 'classnames';
import ProgressiveImage from 'react-progressive-image';
import { goToProduct } from '@/utils';
import { Column } from '@/modules/aline';
import { IMAGE_PRELOADER } from '@/modules/product';
import { ProductCounter } from '@/modules/product-counter';
import { TCartTableColumn, TTableBodyRenderContentArgument } from '@/modules/application';
import { ReactComponent as DeleteIcon } from '@/assets/svg/delete.svg';
import styles from './cart.module.scss';

export const CART_PERSONAL_DATA_FORM_NAME = 'cart-personal-data';

export const CART_PERSONAL_DATA_FORM_FIELDS = {
	NAME: 'name',
	PHONE: 'phone',
	STREET: 'street',
	HOME: 'home',
	FLAT: 'flat',
	STAGE: 'stage',
	ENTRANCE: 'entrance',
	PERSONS: 'persons',
	PAYMENT_METHOD: 'paymentMethod',
	DELIVERY_METHOD: 'deliveryMethod',
	DELIVERY_TIME_METHOD: 'deliveryTimeMethod',
	TAKEAWAY_TIME_METHOD: 'takeAwayTimeMethod',
	DELIVERY_DAY: 'deliveryDay',
	DELIVERY_HOUR: 'deliveryHour',
	DELIVERY_MINUTE: 'deliveryMinute',
	TAKEAWAY_HOUR: 'takeAwayHour',
	TAKEAWAY_MINUTE: 'takeAwayMinute',
	COMMENT: 'comment',
	MONEY_NO_CHANGE: 'isMoneyNoChange',
	MONEY_CHANGE_FROM: 'moneyChangeFrom',
	DATA_SAVE: 'isDataSave',
	FROM_UBI: 'isFromUbi', // Флаг "С Юбилейного"
};

// TODO (perspective) по хорошему конечно
// нужно брать все это сервера, все эти массивы данных, но я этого делать не буду сейчас
// Если будет потребность в изменении этих данных то можно сделать (perspective)

export const PERSONS_VALUES = [
	{ value: 1, textValue: 1 },
	{ value: 2, textValue: 2 },
	{ value: 3, textValue: 3 },
	{ value: 4, textValue: 4 },
	{ value: 5, textValue: 5 },
	{ value: 6, textValue: 6 },
	{ value: 7, textValue: 7 },
	{ value: 8, textValue: 8 },
	{ value: 9, textValue: 9 },
	{ value: 10, textValue: '10 и более' },
];

export enum DELIVERY_METHODS {
	DELIVERY = 'DELIVERY', // Доставка
	TAKEAWAY = 'TAKEAWAY', // Самовывоз
}

export enum DELIVERY_TIME_METHODS {
	ASAP = 'ASAP', // Как можно скорее
	AT_TIME = 'AT_TIME', // Ко времени
}

export const DELIVERY_METHODS_VALUES = [
	{
		value: DELIVERY_TIME_METHODS.ASAP,
		name: 'Как можно скорее',
	},
	{
		value: DELIVERY_TIME_METHODS.AT_TIME,
		name: 'Ко времени',
	},
];

export const TAKEAWAY_METHODS_VALUES = [
	{
		value: DELIVERY_TIME_METHODS.ASAP,
		name: 'В ближайшее время',
	},
	{
		value: DELIVERY_TIME_METHODS.AT_TIME,
		name: 'Подъеду ко времени',
	},
];

export enum PAYMENT_METHODS {
	CARD = 'CARD',
	CASH = 'CASH',
}

export const PAYMENT_METHODS_VALUES = [
	{
		value: PAYMENT_METHODS.CARD,
		name: 'Банковской картой',
	},
	{
		value: PAYMENT_METHODS.CASH,
		name: 'Наличными',
	},
];

export const CART_TABLE_COLUMNS: Array<TCartTableColumn> = [
	{
		name: 'Наименование',
		isHeadVisible: true,
		isBodyVisible: true,
		headWidth: {
			default: 65,
			s: 45,
			xs: 100,
		},
		bodyWidth: {
			default: 65,
			s: 45,
			xs: 100,
		},
		bodyRenderContent({ row, showProductModal }: TTableBodyRenderContentArgument) {
			return (
				<Column width={this.bodyWidth}>
					<div className={cn(
						styles['column'],
						'df',
						'ai-c',
					)}>
						<div className={styles['column-left']}>
							<ProgressiveImage
								src={row.image}
								placeholder={IMAGE_PRELOADER}
							>
								{(src, isLoading) => (
									<div
										className={cn(
											styles['product-image'],
											{
												[styles['product-image--loading']]: isLoading,
											},
										)}
										style={{ backgroundImage: `url(${src})` }}
										onClick={() => showProductModal(row.id)}
									/>
								)}
							</ProgressiveImage>
						</div>
						<div className={styles['column-right']}>
							<div className={styles['product-id']}>
								# {row.id}
							</div>
							<div
								className={styles['product-name']}
								onClick={() => goToProduct(row.categorySlug, row.slug)}
							>
								{row.name}
								{(!!row.quantity && (
									<span className={styles['product-quantity']}>
										({row.quantity} шт.)
									</span>
								)) || (row.productPortionSize && (
									<span className={styles['product-quantity']}>
										({row.productPortionSize})
									</span>
								))}
							</div>
						</div>
					</div>
				</Column>
			);
		},
	},
	{
		name: 'Цена за шт., руб',
		isHeadVisible: true,
		isBodyVisible: true,
		headWidth: {
			default: 10,
			s: 15,
		},
		bodyWidth: {
			default: 10,
			s: 15,
			xs: 33,
		},
		bodyRenderContent({ row }: TTableBodyRenderContentArgument) {
			return (
				<Column width={this.bodyWidth}>
					<div className={styles['column']}>
						<div className={styles['product-price']}>
							{row.price}
							<span className="hidden-s-u"> руб.</span>
						</div>
					</div>
				</Column>
			);
		},
	},
	{
		name: 'Кол-во',
		isHeadVisible: true,
		isBodyVisible: true,
		headWidth: {
			default: 10,
			s: 15,
		},
		bodyWidth: {
			default: 10,
			s: 15,
			xs: 33,
		},
		bodyRenderContent({ row }: TTableBodyRenderContentArgument) {
			return (
				<Column
					width={this.bodyWidth}
					className="p-xs-0"
				>
					<ProductCounter
						id={row.id}
						value={row.count}
					/>
				</Column>
			);
		},
	},
	{
		name: 'Сумма, руб.',
		isHeadVisible: true,
		isBodyVisible: true,
		headWidth: {
			default: 10,
			s: 15,
		},
		bodyWidth: {
			default: 10,
			s: 15,
			xs: 33,
		},
		bodyRenderContent({ row }: TTableBodyRenderContentArgument) {
			return (
				<Column width={this.bodyWidth}>
					<div className={cn(
						styles['column'],
						't-r',
					)}>
						{row.summary > 0 && (
							<div className={styles['product-summary']}>
								{row.summary}
								<span className="hidden-s-u"> руб.</span>
							</div>
						)}
					</div>
				</Column>
			);
		},
	},
	{
		name: 'Удалить',
		isHeadVisible: false,
		isBodyVisible: true,
		headWidth: {
			default: 10,
			s: 15,
		},
		bodyWidth: {
			default: 5,
			s: 10,
			xs: 100,
		},
		bodyRenderContent({ row, onRowDelete }: TTableBodyRenderContentArgument) {
			return (
				<Column width={this.bodyWidth}>
					<div className={cn(styles['column'], styles['column-delete'])}>
						<button
							className={styles['delete-button']}
							onClick={() => onRowDelete(row.id)}
							title="Удалить"
						>
							<DeleteIcon />
						</button>
					</div>
				</Column>
			);
		},
	},
];