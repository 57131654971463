import * as React from 'react';
import { Row } from '@/modules/aline';
import { TCartTableColumn, TCartItem } from '@/modules/application';
import { ITableBodyProps } from './table-types';
import styles from './table.module.scss';

export const TableBody = React.memo(({
	rows,
	bodyColumns,
	onRowDelete,
	showProductModal,
}: ITableBodyProps) => (
	<div className={styles['body']}>
		{!!(rows.length) && rows.map((row: TCartItem) => (
			<div
				className={styles['row']}
				key={row.id}
			>
				<Row noStretch alignCenter>
					{!!(bodyColumns.length) && bodyColumns.map((bodyColumn: TCartTableColumn) => (
						<React.Fragment key={bodyColumn.name}>
							{bodyColumn.isBodyVisible && bodyColumn.bodyRenderContent({
								row,
								onRowDelete,
								showProductModal,
							})}
						</React.Fragment>
					))}
				</Row>
			</div>
		))}
	</div>
));
