import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
	getSettings,
	getCartSummary,
	withSettingsData,
	setHeaderReference,
} from '@/modules/application';
import { cartDataStepPath } from '@/routes/routes-utils';
import { openSidebar } from '@/modules/sidebar';
import { Header } from './header';
import { HEADER_SCROLL_TOP_VIEWING_VALUE } from './header-constants';
import { IHeaderControllerComponentProps } from './header-types';

export class HeaderControllerComponent extends React.Component<IHeaderControllerComponentProps> {
	headerRef: React.RefObject<any> = React.createRef();

	state = {
		isHeaderFixed: false,
	};

	componentDidMount(): void {
		this.props.setHeaderReference(this.headerRef);
		document.addEventListener('scroll', this.headerPositionHandler);
	}

	componentWillUnmount(): void {
		document.removeEventListener('scroll', this.headerPositionHandler);
	}

	headerPositionHandler = () => {
		this.setState({
			isHeaderFixed: window.scrollY > this.headerRef.current.offsetHeight + HEADER_SCROLL_TOP_VIEWING_VALUE,
		});
	};

	render() {
		const {
			settings: { logotype },
			openSidebar,
			cartSummary,
		} = this.props;
		return (
			<Header
				cartSummary={cartSummary}
				logotype={logotype}
				redirectTo={cartDataStepPath}
				headerRef={this.headerRef}
				isHeaderFixed={this.state.isHeaderFixed}
				openSidebar={openSidebar}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	cartSummary: getCartSummary,
	settings: getSettings,
});

const mapDispatchToProps = {
	setHeaderReference,
	openSidebar,
};

export const HeaderController = compose(
	withSettingsData,
	connect(mapStateToProps, mapDispatchToProps),
)(HeaderControllerComponent);
