import * as React from 'react';
import styles from './page-wrapper.module.scss';

export const PageWrapper = React.memo(({ children, style }: {
	children: React.ReactNode,
	style?: any,
}) => (
	<div
		className={styles.pageWrapper}
		style={style}
	>
		{children}
	</div>
));
