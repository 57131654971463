import * as React from 'react';
import { SearchResults } from './search-results';
import { ISearchResultsControllerControllerProps } from './search-results-types';

export class SearchResultsController extends React.PureComponent<ISearchResultsControllerControllerProps> {
	render() {
		const { products } = this.props;
		return (
			<SearchResults
				products={products}
			/>
		);
	}
}