import * as React from 'react';
import cn from 'classnames';
import { Header } from '@/modules/header';
import { Footer } from '@/modules/footer';
import { Container } from '@/modules/aline';
import { YaMap } from '@/components/ya-map';
import { PageWrapper } from '@/components/page-wrapper';
import { IContactsPageProps } from './contacts-page-types';
import styles from './contacts-page.module.scss';

export const ContactsPage = React.memo(({
	phone1,
	phone2,
	email,
	address,
	workStartTime,
	workEndTime,
	latitude,
	longitude,
	mapBaloonContent,
}: IContactsPageProps) => (
	<React.Fragment>
		<PageWrapper>
			<Header />
			<Container>
				<div className={styles['contacts']}>
					{address && (
						<div className={styles['row']}>
							<div className={styles['row-key']}>
								Адрес:
							</div>
							<div className={styles['row-value']}>
								<span className={styles['address']}>
									{address}
								</span>
							</div>
						</div>
					)}

					{(workStartTime && workEndTime) && (
						<div className={styles['row']}>
							<div className={styles['row-key']}>
								Режим работы:
							</div>
							<div className={styles['row-value']}>
								<span className={styles['worktimes']}>
									c {workStartTime}:00 до {workEndTime}:00
								</span>
							</div>
						</div>
					)}

					{(phone1 || phone2) && (
						<div className={styles['row']}>
							<div className={styles['row-key']}>
								Телефон:
							</div>
							<div className={styles['row-value']}>
								{phone1 && (
									<a
										href={`tel: ${phone1}`}
										className={cn(
											styles['phone'],
											styles['phone--1'],
										)}
										title={`Позвонить на номер ${phone1}`}
									>
										{phone1}
									</a>
								)}
								{phone2 && (
									<a
										href={`tel: ${phone2}`}
										className={cn(
											styles['phone'],
											styles['phone--2'],
										)}
										title={`Позвонить на номер ${phone2}`}
									>
										{phone2}
									</a>
								)}
							</div>
						</div>
					)}

					{email && (
						<div className={styles['row']}>
							<div className={styles['row-key']}>
								Электронная почта:
							</div>
							<div className={styles['row-value']}>
								<a
									href={`mailto: ${email}`}
									className={styles['email']}
									title={`Написать на ${email}`}
								>
									{email}
								</a>
							</div>
						</div>
					)}

					{(latitude && longitude) && (
						<YaMap
							latitude={latitude}
							longitude={longitude}
							mapBaloonContent={mapBaloonContent}
						/>
					)}

				</div>
			</Container>
		</PageWrapper>
		<Footer />
	</React.Fragment>
));