export const PATHS = {
	CONTACTS_PAGE: '/contacts',
	DELIVERY_PAGE: '/delivery',
	ERROR_PAGE: '/error',
	HOME_PAGE: '/',
	MENU_PAGE: '/menu',
	SALES_PAGE: '/sales',
	CART_PAGE: '/cart/:step?',
	SEARCH_PAGE: '/search',
	PRODUCT_PAGE: '/menu/:category/:slug?',
	POLICY_PAGE: '/policy',
};
