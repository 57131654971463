import { createSelector } from 'reselect';
import { getCartItems, TCartItem } from '@/modules/application';

const getOwnProps = (state, props) => props;

export const getProductItemCountInCart = createSelector(
	getOwnProps,
	getCartItems,
	(
		ownProps,
		cartItems: Array<TCartItem>,
	): string | number | void => {
		const { productItem } = ownProps;
		const cartCountItem = cartItems?.find((cartItem: TCartItem) => cartItem.id === productItem.id);
		return cartCountItem?.count;
	},
);