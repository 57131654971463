import * as React from 'react';
import { Container } from '@/modules/aline';
import { Header } from '@/modules/header';
import { Footer } from '@/modules/footer';
import { PageWrapper } from '@/components/page-wrapper';
import { SaleItem } from './components';
import { ISalesPageProps, TSale } from './sales-page-types';
import styles from './sales-page.module.scss';

export const SalesPage = React.memo(({ sales }: ISalesPageProps) => (
	<React.Fragment>
		<PageWrapper>
			<Header />
			<main>
				<Container>
					<div className={styles['sales']}>
						{!!(sales?.length) && sales?.map((saleItem: TSale) => (
							<SaleItem
								key={saleItem.id}
								saleItem={saleItem}
							/>
						))}
					</div>
				</Container>
			</main>
		</PageWrapper>
		<Footer />
	</React.Fragment>
));
