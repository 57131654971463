import * as React from 'react';
import { connect } from 'react-redux';
import { setCategoriesReference } from '@/modules/application';
import { Category } from './category';
import { ICategoryControllerComponent } from './category-types';

export class CategoryControllerComponent extends React.PureComponent<ICategoryControllerComponent> {
	categoryRef: React.RefObject<any> = React.createRef();

	componentDidMount(): void {
		this.props.setCategoriesReference({
			categoryId: this.props.categoryItem.id,
			categoryRef: this.categoryRef.current,
		});
	}

	render() {
		return (
			<Category
				categoryItem={this.props.categoryItem}
				categoryRef={this.categoryRef}
			/>
		);
	}
}

const mapDispatchToProps = {
	setCategoriesReference,
};

export const CategoryController = connect(null, mapDispatchToProps)(CategoryControllerComponent);
