import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getSettings } from '@/modules/application';
import { IFooterControllerComponentProps } from './footer-types';
import { Footer } from './footer';

export class FooterControllerComponent extends React.PureComponent<IFooterControllerComponentProps> {
	render() {
		const { settings: { footerBgImage, phone1, phone2, footerText } } = this.props;
		return (
			<Footer
				bgImage={footerBgImage}
				phone1={phone1}
				phone2={phone2}
				footerText={footerText}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	settings: getSettings,
});

export const FooterController = connect(mapStateToProps)(FooterControllerComponent);
