import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { compact } from 'lodash';
import { reducers } from './reducers';

export const store = createStore(
	reducers,
	compose(
		applyMiddleware(thunk),
		// @ts-ignore
		...compact([window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ maxAge: 10 })]),
	),
);

// if (process.env.NODE_ENV === 'development') {
// @ts-ignore
// window.getState = store.getState;
// }