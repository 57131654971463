import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStoreManager } from '@/modules/store-manager';
import { withProductsData, withTitles } from '@/modules/application';
import { STORE_KEYS } from '@/store/store-constants';
import { SalesPage } from './sales-page';
import { getSalesFromServer } from './sales-page-actions';
import { getSales } from './sales-page-selectors';
import { ISalesPageControllerComponentProps } from './sales-page-types';

export class SalesPageControllerComponent extends React.PureComponent<ISalesPageControllerComponentProps> {
	componentDidMount(): void {
		this.props.getSalesFromServer();
	}

	render() {
		return (
			<SalesPage
				sales={this.props.sales}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	sales: getSales,
});

const mapDispatchToProps = {
	getSalesFromServer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const SalesPageController = compose(
	withStoreManager(STORE_KEYS.SALES_PAGE),
	withTitles,
	withRouter,
	withProductsData,
	connector,
)(SalesPageControllerComponent);