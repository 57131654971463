import * as React from 'react';
import cn from 'classnames';
import ProgressiveImage from 'react-progressive-image';
import { history } from '@/history';
import { Button } from '@/modules/aline';
import { PATHS } from '@/routes/routes-constants';
import { IMAGE_PRELOADER } from '@/modules/product/product-constants';
import { ISaleItemProps } from '../sales-page-types';
import styles from './sale-item.module.scss';

export const SaleItem = React.memo(({ saleItem }: ISaleItemProps) => {
	const [isActive, setIsActive] = React.useState(false);
	const toPage = () => history.push(saleItem.page || PATHS.MENU_PAGE);

	return (
		<div
			className={styles['sale']}
			title="Нажмите, чтобы посмотреть подробнее"
		>
			<ProgressiveImage
				src={saleItem.image}
				placeholder={IMAGE_PRELOADER}
			>
				{(src, isLoading) => (
					<div
						className={cn(
							styles['sale-image'],
							{
								[styles['sale-image--loading']]: isLoading,
							},
						)}
						style={{ backgroundImage: `url(${src})` }}
						onClick={() => setIsActive(!isActive)}
					/>
				)}
			</ProgressiveImage>
			{isActive && (
				<React.Fragment>
					<div
						className={cn(
							styles['sale-text'],
							'wysiwyg-data',
						)}
						dangerouslySetInnerHTML={{ __html: saleItem.text }}
					/>
					{saleItem.page && saleItem.buttonText && (
						<div className={styles['sale-button']}>
							<Button
								onClick={toPage}
								size="small"
								color="green"
								border={3}
								bold
							>
								{saleItem.buttonText}
							</Button>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
	);
});