import * as React from 'react';
import { Container } from '@/modules/aline';
import { Header } from '@/modules/header';
import { Footer } from '@/modules/footer';
import { Heading } from '@/components/heading';
import { PageWrapper } from '@/components/page-wrapper';
import { IPolicyPageProps } from './policy-page-types';
import styles from './policy-page.module.scss';

export const PolicyPage = React.memo(({ policy }: IPolicyPageProps) => (
	<React.Fragment>
		<PageWrapper>
			<Header />
			{policy?.name && (
				<Heading
					size={3}
					text={policy.name}
					textCenter
					className="ptb-30"
				/>
			)}
			<Container>
				{policy?.text && (
					<div
						className={styles['policy']}
						dangerouslySetInnerHTML={{ __html: policy.text }}
					/>
				)}
			</Container>
		</PageWrapper>
		<Footer />
	</React.Fragment>
));
