import * as React from 'react';
import cn from 'classnames';
import { ISwitchTabProps, TTab } from './switch-tab-types';
import styles from './switch-tab.module.scss';

export const SwitchTab = ({
	tabs,
	isSimple = false,
	label,
	input: { onChange, value },
	// TODO (perspective aline-ui) в универсальном компоненте, не должно быть таких пропсов.
	// TODO (perspective aline-ui) Их нужно обрабатывать через обхект
	address,
	formValues,
	takeAwayTimeOptions,
	deliveryTimeOptions,
}: ISwitchTabProps) => {
	const onTabClickHandler = (value: string) => {
		onChange(value);
	};

	return (
		<div
			className={styles['switch-block']}
		>
			{label && (
				<label className={styles['label']}>
					{label}
				</label>
			)}
			<div className={styles['switch-tab']}>
				{!!tabs.length && tabs.map((tab: TTab) => (
					<div
						key={tab.value}
						className={cn(
							styles['tab'],
							{
								[styles['tab--active']]: tab.value === value,
							},
						)}
						onClick={() => onTabClickHandler(tab.value)}
					>
						{tab.name}
					</div>
				))}
			</div>
			{!isSimple && (
				<div className={styles['switch-body']}>
					{!!tabs.length && tabs.map((tab: TTab) => (
						tab.value === value ? tab.renderContent({
							address,
							formValues,
							takeAwayTimeOptions,
							deliveryTimeOptions,
						}) : null
					))}
				</div>
			)}
		</div>
	);
};
