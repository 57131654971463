import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Carousel } from '@/components/carousel';
import { getSettings } from '@/modules/application';
import { getSortedCarouselItems } from './carousel-selectors';
import { getCarouselItems } from './carousel-actions';
import { ICarouselControllerComponent } from './carousel-types';

export class CarouselControllerComponent extends React.PureComponent<ICarouselControllerComponent> {
	componentDidMount(): void {
		this.props.getCarouselItems();
	}

	render() {
		const { carouselItems, settings: { isSliderAutoplay, sliderDelay } } = this.props;
		return (
			<React.Fragment>
				{!!(carouselItems.length) && (
					<Carousel
						carouselItems={carouselItems}
						isSliderAutoplay={isSliderAutoplay}
						sliderDelay={sliderDelay}
					/>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	carouselItems: getSortedCarouselItems,
	settings: getSettings,
});

const mapDispatchToProps = {
	getCarouselItems,
};

export const CarouselController = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CarouselControllerComponent);
