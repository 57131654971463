import { history } from '@/history';

export const sortByOrder = (a, b) => (a.order - b.order);

export const sortBy = (propertyName: string) => (a, b) => (a[propertyName] - b[propertyName]);

// Округление до десятков
export const numberRound10 = (value: number): number => Math.round(value / 10) * 10;

export const getRandomInt = (min: number, max: number) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min;
};

export const getRandomFromArray = (array: Array<any>, numberOfElements: number) => {
	let length = array.length;
	if (numberOfElements > length) {
		return array.slice(0, numberOfElements);
	}
	let result = new Array(numberOfElements);
	const	taken = new Array(length);
	while (numberOfElements--) {
		const randomIndex = Math.floor(Math.random() * length);
		result[numberOfElements] = array[randomIndex in taken ? taken[randomIndex] : randomIndex];
		taken[randomIndex] = --length in taken ? taken[length] : length;
	}
	return result;
};

export const goToProduct = (categorySlug: string, itemSlug: string) => {
	history.push(`/menu/${categorySlug}/${itemSlug}`);
	window.scrollTo({ top: 0 });
};

export const getNext7Days = (date: Date = new Date()) => {
	return new Array(7).fill(null).map((day, index) => date.getDate() + index);
};

export const checkDay = (date: number) => {
	const currentDate = new Date();
	if (date == currentDate.getDate()) {
		return 'Сегодня';
	}
	if (date == currentDate.getDate() + 1) {
		return 'Завтра';
	}
	return date;
};

// TODO (perspective) проверить что можно почитстить и типизация
export const getNextDayHours = ({
	date = new Date(),
	selectedDay,
	startHour = 10,
	endHour = 23,
}: {
	date: Date,
	selectedDay: string | number,
	startHour?: number | string,
	endHour?: number | string,
}) => {
	const currentDay = date.getDate();
	const currentHour = date.getHours();
	const selectedDayNumber = Number(selectedDay);
	const endHourNumber = Number(endHour);
	const startHourNumber = Number(startHour);

	const isSameDay = currentDay === selectedDayNumber;
	const isInWorkingTime = (currentHour > startHourNumber) && (currentHour < endHourNumber);

	const restHours = isSameDay && isInWorkingTime
		? (endHourNumber - (currentHour + 1))
		: (endHourNumber - startHourNumber);

	return restHours > 0
		? new Array(restHours).fill(null).map((_, index) => (
			isSameDay && isInWorkingTime
				? ((currentHour + 1) + index)
				: (startHourNumber + index)
		))
		: [];
};

// TODO (perspective) проверить что можно почитстить и типизация
export const getNextHourMinutes = (
	hour?: number,
	correctionMinutes: number = 30,
): Array<number> => {
	const date = new Date();
	const currentHour = date.getHours();
	const isCurrentHour = Number(hour) === currentHour;
	const correction = isCurrentHour ? correctionMinutes : 0;

	const currentMinute = isCurrentHour ? numberRound10(date.getMinutes()) : 0;
	const restMinutes = (60 - (currentMinute + correction)) / 10;

	return restMinutes > 0
		? new Array(restMinutes).fill(null).map((_, index) => currentMinute + correction + (index * 10))
		: [];
};
