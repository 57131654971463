import { carouselStub } from '@/stubs';
import { SET_CAROUSEL_ITEMS } from './carousel-actions';

export const carouselState = {
	carouselItems: process.env.NODE_ENV === 'development' ? carouselStub : [],
};

export const carouselReducer = (state = carouselState, { type, payload }) => {
	switch (type) {
	case SET_CAROUSEL_ITEMS:
		return {
			...state,
			carouselItems: payload,
		};
	default:
		return state;
	}
};