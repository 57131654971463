import * as React from 'react';
import cn from 'classnames';

export const Container = ({ children, className }: {
	children?: React.ReactNode,
	className?: string,
}) => (
	<div className={cn(
		'c',
		className,
	)}>
		{children}
	</div>
);
