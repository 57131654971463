import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSettings, withTitles } from '@/modules/application';
import { DeliveryPage } from './delivery-page';
import { IDeliveryPageControllerComponentProps } from './delivery-page-types';

export class DeliveryPageControllerComponent extends React.PureComponent<IDeliveryPageControllerComponentProps> {
	render() {
		const { settings: {
			phone1,
			phone2,
			deliveryConditions,
			deliveryContactBlockText,
			deliveryContactBlockImage,
			deliveryPaymentMethods,
			deliveryOrderTypes,
			deliveryZonesIFrame,
		} } = this.props;

		return (
			<DeliveryPage
				conditions={deliveryConditions}
				phone1={phone1}
				phone2={phone2}
				contactBlockText={deliveryContactBlockText}
				contactBlockImage={deliveryContactBlockImage}
				paymentMethods={deliveryPaymentMethods}
				orderTypes={deliveryOrderTypes}
				deliveryZonesIFrame={deliveryZonesIFrame}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	settings: getSettings,
});

const connector = connect(mapStateToProps);

export const DeliveryPageController = compose(
	withTitles,
	withRouter,
	connector,
)(DeliveryPageControllerComponent);