import * as React from 'react';
import { Button, Container } from '@/modules/aline';
import { toHomePage } from '@/routes/routes-utils';
import { PageWrapper } from '@/components/page-wrapper';
import styles from './error-page.module.scss';

export const ErrorPage = React.memo(() => (
	<PageWrapper>
		<Container>
			<div className={styles['error-block']}>
				<div className={styles['accent']}>
					404
				</div>
				<div className={styles['description']}>
					Страница не найдена.
				</div>
				<div className={styles['button']}>
					<Button
						onClick={toHomePage}
						size="small"
						color="black"
						border={3}
						bold
					>
						Перейти на главную
					</Button>
				</div>
			</div>
		</Container>
	</PageWrapper>
));
