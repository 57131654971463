import { salesStub } from '@/stubs';
import { SET_SALES } from './sales-page-actions';

const salesPageState = {
	sales: process.env.NODE_ENV === 'development' ? salesStub : {},
};

export const salesPageReducer = (state = salesPageState, { type, payload }) => {
	switch (type) {
	case SET_SALES:
		return {
			...state,
			sales: payload,
		};
	default:
		return state;
	}
};
