import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTitles } from '@/modules/application';
import { withStoreManager } from '@/modules/store-manager';
import { STORE_KEYS } from '@/store/store-constants';
import { PolicyPage } from './policy-page';
import { getPolicyFromServer } from './policy-page-actions';
import { getPolicy } from './policy-page-selectors';
import { IPolicyPageControllerComponentProps } from './policy-page-types';

export class PolicyPageControllerComponent extends React.PureComponent<IPolicyPageControllerComponentProps> {
	componentDidMount(): void {
		this.props.getPolicyFromServer();
	}

	render() {
		return (
			<PolicyPage
				policy={this.props.policy}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	policy: getPolicy,
});

const mapDispatchToProps = {
	getPolicyFromServer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const PolicyPageController = compose(
	withStoreManager(STORE_KEYS.POLICY_PAGE),
	withTitles,
	withRouter,
	connector,
)(PolicyPageControllerComponent);
