import { reducer as formReducer } from 'redux-form';
import { storeManager } from '@/modules/store-manager';
import { applicationReducer, cartReducer } from '@/modules/application';
import { modalsReducer } from '@/modules/modals';
import { carouselReducer } from '@/modules/carousel';
import { sidebarReducer } from '@/modules/sidebar';
import { policyPageReducer } from '@/pages/policy-page';
import { salesPageReducer } from '@/pages/sales-page';
import {
	STORE_KEYS,
} from './store-constants';

export const staticReducers = {
	[STORE_KEYS.APPLICATION]: applicationReducer,
	[STORE_KEYS.CAROUSEL]: carouselReducer,
	[STORE_KEYS.CART]: cartReducer,
	[STORE_KEYS.FORM]: formReducer,
	[STORE_KEYS.MODALS]: modalsReducer,
	[STORE_KEYS.SIDEBAR]: sidebarReducer,
};

export const dynamicReducers = {
	[STORE_KEYS.POLICY_PAGE]: policyPageReducer,
	[STORE_KEYS.SALES_PAGE]: salesPageReducer,
};

export const reducers = storeManager(staticReducers, dynamicReducers);
