import { PATHS } from '@/routes/routes-constants';
import { TNavigationItems } from './navigation-types';

export enum NAVIGATION_MENU_ITEMS_TITLES {
	MENU = 'Меню',
	SALES_AND_NEWS = 'Акции и новости',
	DELIVERY_AND_PAYMENT = 'Доставка и оплата',
	CONTACTS = 'Контакты',
}

export const NAVIGATION_MENU_ITEMS: TNavigationItems = [
	{
		title: NAVIGATION_MENU_ITEMS_TITLES.MENU,
		slug: PATHS.MENU_PAGE,
		order: 100,
	},
	{
		title: NAVIGATION_MENU_ITEMS_TITLES.SALES_AND_NEWS,
		slug: PATHS.SALES_PAGE,
		order: 200,
	},
	{
		title: NAVIGATION_MENU_ITEMS_TITLES.DELIVERY_AND_PAYMENT,
		slug: PATHS.DELIVERY_PAGE,
		order: 300,
	},
	{
		title: NAVIGATION_MENU_ITEMS_TITLES.CONTACTS,
		slug: PATHS.CONTACTS_PAGE,
		order: 400,
	},
];