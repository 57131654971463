import * as React from 'react';
import cn from 'classnames';
import { sortByOrder } from '@/utils';
import { INavigationSidebar, TNavigationEntity } from './navigation-types';
import styles from './navigation.module.scss';

export const NavigationSidebar = React.memo(({ menuItems, onMenuClick }: INavigationSidebar) => (
	<nav className={cn(
		styles['navigation'],
		styles['navigation--sidebar'],
	)}>
		<ul className={styles['navigation-list']}>
			{!!(menuItems?.length) && menuItems.sort(sortByOrder).map(({ slug, title }: TNavigationEntity) => (
				<li
					key={slug}
					className={styles['navigation-list-item']}
					title={title}
				>
					<div
						onClick={() => onMenuClick(slug)}
						className={styles['navigation-link']}
					>
						{title}
					</div>
				</li>
			))}
		</ul>
	</nav>
));
