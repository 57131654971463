import * as React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import { store } from '@/store/store';
import { history } from '@/history';
import { ProductModal } from '@/modules/modals';
import { Sidebar } from '@/modules/sidebar';
import { ScrollToTop } from '@/components/scroll-to-top';
import { routes, TRoute } from './routes';

export const App = (): React.ReactNode => (
	<Provider store={store}>
		<Router history={history}>
			<Switch>
				{routes.map((route: TRoute) => (
					<Route
						key={`route-${route.path}`}
						path={route.path}
						component={route.component}
						strict
						exact={route.exact !== false}
					/>
				))}
			</Switch>
			<ScrollToTop />
			<ProductModal />
			<Sidebar />
		</Router>
	</Provider>
);