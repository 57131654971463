import { createSelector } from 'reselect';
import {
	getProducts,
	getCartItems,
	TProducts,
	TProduct,
	TCartItem,
} from '@/modules/application';

const getOwnProps = (state, props) => props;

export const getProduct = createSelector(
	getOwnProps,
	getProducts,
	(
		ownProps: { match },
		products: TProducts,
	) => {

		const { category, slug } = ownProps.match.params;
		return products?.find((product: TProduct) => product.categorySlug === category && product.slug === slug);
	},
);

export const getProductItemCountInCart = createSelector(
	getCartItems,
	getProduct,
	(
		cartItems: Array<TCartItem>,
		productItem?: TProduct,
	): string | number | void => {
		const cartCountItem = cartItems?.find((cartItem: TCartItem) => cartItem.id === productItem?.id);
		return cartCountItem?.count;
	},
);
