import * as React from 'react';
import cn from 'classnames';
import { Row, Column } from '@/modules/aline';
import { TCartTableColumn } from '@/modules/application';
import { ITableHeadProps } from './table-types';
import styles from './table.module.scss';

export const TableHead = ({ headColumns }: ITableHeadProps) => (
	<div className={cn(
		styles['head'],
		'hidden-xs-d',
	)}>
		<Row noStretch alignCenter>
			{headColumns.map(({ headWidth, name, isHeadVisible }: TCartTableColumn) => (
				isHeadVisible && (
					<Column
						width={headWidth}
						key={name}
					>
						<div className={cn(
							styles['column'],
							styles['column-gray'],
						)}>
							<span>{name}</span>
						</div>
					</Column>
				)
			))}
		</Row>
	</div>
);
