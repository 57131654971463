import * as React from 'react';
import cn from 'classnames';
import { ICaloriesTableProps } from './calories-table-types';
import styles from './calories-table.module.scss';

export const CaloriesTable = React.memo(({ calories, productPortionWeight }: ICaloriesTableProps) => (
	<React.Fragment>
		{calories && (
			<div className={styles['calories']}>
				<table className={styles['calories-table']}>
					<tbody>
						<tr className={styles['calories-row']}>
							<th className={styles['calories-cell']}></th>
							<th className={styles['calories-cell']}>Белки</th>
							<th className={styles['calories-cell']}>Жиры</th>
							<th className={styles['calories-cell']}>Углеводы</th>
							<th className={styles['calories-cell']}>кКал</th>
						</tr>
						<tr className={styles['calories-row']}>
							<td className={styles['calories-cell']}>В 100 г</td>
							<td className={styles['calories-cell']}>{calories?.proteins100}</td>
							<td className={styles['calories-cell']}>{calories?.fats100}</td>
							<td className={styles['calories-cell']}>{calories?.carbohydrates100}</td>
							<td className={styles['calories-cell']}>{calories?.kkal100}</td>
						</tr>
						<tr className={styles['calories-row']}>
							<td className={styles['calories-cell']}>Порция</td>
							<td className={styles['calories-cell']}>{calories?.proteinsInPortion}</td>
							<td className={styles['calories-cell']}>{calories?.fatsInPortion}</td>
							<td className={styles['calories-cell']}>{calories?.carbohydratesInPortion}</td>
							<td className={styles['calories-cell']}>{calories?.kkalInPortion}</td>
						</tr>
						{productPortionWeight && (
							<tr className={styles['calories-row']}>
								<td colSpan={5} className={cn(
									styles['calories-cell'],
									styles['calories--weight'],
								)}>Вес порции: {productPortionWeight} г</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		)}
	</React.Fragment>
));
