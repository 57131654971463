import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from './sidebar-actions';
import { TSidebarState } from './sidebar-types';

export const siderbarState: TSidebarState = {
	visibility: false,
};

export const sidebarReducer = (state: TSidebarState = siderbarState, { type }) => {
	switch (type) {
	case OPEN_SIDEBAR:
		return {
			visibility: true,
		};
	case CLOSE_SIDEBAR:
		return {
			visibility: false,
		};
	default:
		return state;
	}
};
