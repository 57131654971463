import { CART_CLIENT_DATA_LOCAL_STORAGE_KEY } from './application-constants';

export const clientDataStorage = {
	fetch() {
		return JSON.parse(localStorage.getItem(CART_CLIENT_DATA_LOCAL_STORAGE_KEY) || '{}');
	},
	async save(clientData) {
		await localStorage.setItem(CART_CLIENT_DATA_LOCAL_STORAGE_KEY, JSON.stringify(clientData));
	},
	clear() {
		localStorage.removeItem(CART_CLIENT_DATA_LOCAL_STORAGE_KEY);
	},
};