import { compile } from 'path-to-regexp';
import { history } from '@/history';
import { PATHS } from './routes-constants';

export const getToPath = (path: string, params?: any) => compile(path, { encode: encodeURIComponent })(params);

export const toHomePage = () => history.push(PATHS.HOME_PAGE);

export const toMenuPage = () => history.push(PATHS.MENU_PAGE);

export const cartDataStepPath = getToPath(PATHS.CART_PAGE);

export const personalStepPath = getToPath(PATHS.CART_PAGE, { step: 'personal' });
