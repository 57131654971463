import * as React from 'react';
import cn from 'classnames';
import NumberFormat from 'react-number-format';
import { IPhoneInputProps } from './phone-input-types';
import styles from './phone-input.module.scss';

export const PhoneInput = ({
	label,
	input,
	meta: { touched, error, warning },
}: IPhoneInputProps) => (
	<React.Fragment>
		{label && (
			<label className={styles['label']}>
				{label}
			</label>
		)}
		<div className={cn(
			styles['wrapper'],
			{
				[styles['wrapper--error']]: error,
				[styles['wrapper--warning']]: warning,
				// [styles['wrapper--welldone']]: !error && !warning,
			},
		)}>
			<NumberFormat
				className={styles['input']}
				{...input}
				value={input.value}
				onChange={input.onChange}
				type="tel"
				format="+7 (###) ### ## ##"
				allowEmptyFormatting
			/>
		</div>
		{error && touched && (
			<div className={styles['error']}>
				{error}
			</div>
		)}
		{!error && warning && touched && (
			<div className={styles['warning']}>
				{warning}
			</div>
		)}

	</React.Fragment>
);