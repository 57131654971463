import * as React from 'react';
import { Sidebar as AlineSidebar } from '@/modules/aline';
import { Logo } from '@/components/logo';
import { PATHS } from '@/routes/routes-constants';
import { cartDataStepPath } from '@/routes/routes-utils';
import { SearchPresenter } from '@/components/search-presenter';
import { CartPresenter } from '@/components/cart-presenter';
import { Navigation } from '@/modules/navigation';
import { ISidebarProps } from './sidebar-types';
import styles from './sidebar.module.scss';

export const Sidebar = React.memo(({
	closeSidebar,
	isOpen,
	logotype,
	phone1,
	phone2,
}: ISidebarProps) => (
	<AlineSidebar
		closeSidebar={closeSidebar}
		isOpen={isOpen}
		className="hidden-s-u"
	>
		<div className={styles['sidebar-data']}>
			<Logo
				closeSidebar={closeSidebar}
				image={logotype}
				left
			/>
			{(phone1 || phone2) && (
				<div className={styles['phones']}>
					{phone1 && (
						<a href={`tel:${phone1}`} className={styles['phone']}>
							{phone1}
						</a>
					)}
					{phone2 && (
						<a href={`tel:${phone2}`} className={styles['phone']}>
							{phone2}
						</a>
					)}
				</div>
			)}
			<div className={styles['navigation-block']}>
				<Navigation
					isSidebar
					closeSidebar={closeSidebar}
				/>
				<SearchPresenter
					closeSidebar={closeSidebar}
					redirectTo={PATHS.SEARCH_PAGE}
					className="mt-30"
				/>
				<CartPresenter
					text="Перейти в корзину"
					closeSidebar={closeSidebar}
					redirectTo={cartDataStepPath}
					className="mt-30"
				/>
			</div>
		</div>
	</AlineSidebar>
));
