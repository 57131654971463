import { createSelector } from 'reselect';
import { STORE_KEYS } from '@/store/store-constants';
import { TApplicationState } from '@/modules/application';
import { IModalsState, TModalStoreEntity } from './modals-types';

const getModalsState = (state: TApplicationState): IModalsState => state[STORE_KEYS.MODALS];

export const getModalStoreEntity = (modalName: string) => createSelector(
	getModalsState,
	(modalsState: IModalsState): TModalStoreEntity | void => modalsState[modalName],
);

export const getModalVisibility = (modalName: string) => createSelector(
	getModalStoreEntity(modalName),
	(modalStoreEntity: TModalStoreEntity): boolean => modalStoreEntity?.isOpen,
);

export const getModalData = (modalName: string) => createSelector(
	getModalStoreEntity(modalName),
	(modalStoreEntity: TModalStoreEntity): any => modalStoreEntity?.data,
);
