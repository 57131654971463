import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Route, withRouter } from 'react-router-dom';
import { PATHS } from '@/routes/routes-constants';
import { personalStepPath, cartDataStepPath } from '@/routes/routes-utils';
import {
	getRememberProductProposals,
	deleteCartItemFromStorage,
	changeCartItemCount,
	clearCartData,
	getCartItems,
	getEmptyCartAttentionText,
	getCartSummary,
} from '@/modules/application';
import { withModals, PRODUCT_MODAL_NAME } from '@/modules/modals';
import { ICartControllerComponentProps } from './cart-types';
import { CART_TABLE_COLUMNS } from './cart-constants';
import { CartEmpty } from './components/cart-empty';
import { CartDataStep, CartPersonalStep } from './steps';

class CartControllerComponent extends React.Component<ICartControllerComponentProps> {
	onRowDelete = (id: string | number): void => {
		this.props.deleteCartItemFromStorage(id);
	};

	clearCartData = () => this.props.clearCartData();

	showProductModal = (id: string) => {
		this.props.showModal({
			name: PRODUCT_MODAL_NAME,
			data: {
				id,
			},
		});
	};

	render() {
		const {
			cartItems,
			emptyCartAttentionText,
			cartSummary,
			rememberProductProposals,
		} = this.props;

		if (cartItems.length === 0) {
			return (
				<CartEmpty
					buttonRedirectTo={PATHS.MENU_PAGE}
					emptyCartAttentionText={emptyCartAttentionText}
				/>
			);
		}

		return (!!cartItems.length && (
			<Switch>
				<Route
					path={personalStepPath}
					render={() => (
						<CartPersonalStep
							clearCartData={this.clearCartData}
						/>
					)}
				/>
				<Route
					path={cartDataStepPath}
					render={() => (
						<CartDataStep
							rows={cartItems}
							headColumns={CART_TABLE_COLUMNS}
							bodyColumns={CART_TABLE_COLUMNS}
							onRowDelete={this.onRowDelete}
							personalStepPath={personalStepPath}
							cartSummary={cartSummary}
							clearCartData={this.clearCartData}
							rememberProductProposals={rememberProductProposals}
							showProductModal={this.showProductModal}
						/>
					)}
				/>
			</Switch>
		));
	}
}

const mapStateToProps = createStructuredSelector({
	cartItems: getCartItems,
	emptyCartAttentionText: getEmptyCartAttentionText,
	cartSummary: getCartSummary,
	rememberProductProposals: getRememberProductProposals,
});

const mapDispatchToProps = {
	deleteCartItemFromStorage,
	changeCartItemCount,
	clearCartData,
};

export const CartController: React.ComponentType<any> = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withModals,
	withRouter,
)(CartControllerComponent);
