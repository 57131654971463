import * as React from 'react';
import { Field } from 'redux-form';
import { NumberInput, SelectInput, TextInput } from '@/components/inputs';
import { Checkbox, SwitchTab } from '@/modules/aline';
import {
	CART_PERSONAL_DATA_FORM_FIELDS,
	DELIVERY_METHODS_VALUES,
	DELIVERY_METHODS,
	TAKEAWAY_METHODS_VALUES,
	DELIVERY_TIME_METHODS,
} from '../../cart-constants';
import { TTabsRenderContentProps } from './cart-personal-step-types';
import styles from './cart-personal-step.module.scss';

export const CORRECTION_MINUTES = 30;

export const ORDER_DONE_TITLE_DEFAULT = 'Спасибо за ваш заказ!';
export const ORDER_DONE_MESSAGE_DEFAULT = 'Менеджер суши-бара свяжется с вами' +
	' в самое ближайшее время. Благодарим за ожидание.';
export const ORDER_DONE_BUTTON_TITLE_DEFAULT = 'Хорошо';
export const ORDER_DONE_ERROR_TEXT_DEFAULT = 'Произошла ошибка создания заказа, пожалуйста, попробуйте позже.';

export const personalStepTabs = [
	{
		name: 'Доставка',
		value: DELIVERY_METHODS.DELIVERY,
		renderContent(props?: TTabsRenderContentProps) {
			return (
				<React.Fragment key={this.value}>
					<div className={styles['row']}>
						{/* Флаг, с юбилейного */}
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.FROM_UBI}
							component={Checkbox}
							label="Доставка по Центру"
							type="checkbox"
						/>
					</div>
					<div className={styles['row']}>
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.STREET}
							component={TextInput}
							label="Улица"
						/>
					</div>
					<div className={styles['row']}>
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.HOME}
							component={TextInput}
							label="Номер дома, строения"
						/>
					</div>
					<div className={styles['row']}>
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.FLAT}
							component={NumberInput}
							label="Номер квартиры, офиса"
						/>
					</div>
					<div className={styles['row']}>
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.ENTRANCE}
							component={NumberInput}
							label="Номер подъезда"
						/>
					</div>
					<div className={styles['row']}>
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.STAGE}
							component={NumberInput}
							label="Этаж"
						/>
					</div>
					<div className={styles['row']}>
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_TIME_METHOD}
							label="Доставить:"
							component={SwitchTab}
							tabs={DELIVERY_METHODS_VALUES}
							isSimple
						/>
					</div>
					{props?.formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_TIME_METHOD] === DELIVERY_TIME_METHODS.AT_TIME
						&& (
							<React.Fragment>
								<div className={styles['row']}>
									<Field
										name={CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY}
										component={SelectInput}
										label="Выберите день"
										options={props?.deliveryTimeOptions?.days}
										disabledValue="Выберите день"
										defaultValue={''}
									/>
								</div>

								{props?.formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY]
									&& !!(props?.deliveryTimeOptions?.hours?.length) && (
										<div className={styles['row']}>
											<Field
												name={CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR}
												component={SelectInput}
												label="Выберите часы"
												options={props?.deliveryTimeOptions?.hours}
												disabledValue="Выберите часы"
											/>
										</div>
									)
								}

								{props?.formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR]
									&& !!(props?.deliveryTimeOptions?.minutes?.length) && (
										<div className={styles['row']}>
											<Field
												name={CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_MINUTE}
												component={SelectInput}
												label="Выберите минуты"
												options={props?.deliveryTimeOptions?.minutes}
												disabledValue="Выберите минуты"
											/>
										</div>
									)
								}
							</React.Fragment>
						)
					}
				</React.Fragment>
			);
		},
	},
	{
		name: 'Самовывоз',
		value: DELIVERY_METHODS.TAKEAWAY,
		renderContent(props?: TTabsRenderContentProps) {
			return (
				<React.Fragment key={this.value}>
					{props?.address && (
						<div className={styles['row']}>
							<div className={styles['address-description']}>Забрать заказ вы можете по адресу:</div>
							<div className={styles['address']}>
								{props?.address}
							</div>
						</div>
					)}
					<div className={styles['row']}>
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_TIME_METHOD}
							label="Заберу:"
							component={SwitchTab}
							tabs={TAKEAWAY_METHODS_VALUES}
							isSimple
						/>
					</div>
					{props?.formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_TIME_METHOD] === DELIVERY_TIME_METHODS.AT_TIME
						&& (
							<React.Fragment>
								{!!(props?.takeAwayTimeOptions?.hours?.length) && (
									<div className={styles['row']}>
										<Field
											name={CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR}
											component={SelectInput}
											label="Выберите часы"
											options={props?.takeAwayTimeOptions?.hours}
											disabledValue="Выберите часы"
										/>
									</div>
								)}
								{props?.formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR]
									&& !!(props?.takeAwayTimeOptions?.minutes?.length) && (
										<div className={styles['row']}>
											<Field
												name={CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_MINUTE}
												component={SelectInput}
												label="Выберите минуты"
												options={props?.takeAwayTimeOptions?.minutes}
												disabledValue="Выберите минуты"
											/>
										</div>
									)
								}
							</React.Fragment>
						)
					}
				</React.Fragment>
			);
		},
	},
];

// NOTE: временно отключаем Самовывоз, так как его нету, только доставка в данный период
// В дальнейшем, когда появится самовывоз, просто удалить эту константу и заюзать personalStepTabs
// По идее и по хорошему нужно просто добавить свойтсво в инстанс disabled
// и смотреть на него в компоненте который рисует табы, но пока что так
export const onlyDeliveryTab = [personalStepTabs[0]];
