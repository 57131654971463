import { createSelector } from 'reselect';
import { STORE_KEYS } from '@/store/store-constants';
import { TSalesPageState } from './sales-page-types';

const getSalesPageState = (state) => state[STORE_KEYS.SALES_PAGE];

export const getSales = createSelector(
	getSalesPageState,
	(salesPageState?: TSalesPageState) => salesPageState?.sales,
);
