import * as React from 'react';
import { Category } from '@/modules/category';
import { IStructuredCategory } from '@/modules/application';
import { IMenuProps } from './menu-types';

export const Menu = React.memo(({
	categories,
}: IMenuProps) => (
	<React.Fragment>
		{categories && categories.map((categoryItem: IStructuredCategory) => (
			<Category
				key={categoryItem.id}
				categoryItem={categoryItem}
			/>
		))}
	</React.Fragment>
));
