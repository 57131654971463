import { ADD_REDUCER, DELETE_REDUCER } from './store-manager-constants';

export const addReducer = (payload: string) => ({
	type: ADD_REDUCER,
	payload,
});

export const deleteReducer = (payload: string) => ({
	type: DELETE_REDUCER,
	payload,
});