import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getSettings } from '@/modules/application';
import { Socials } from './socials';

const mapStateToProps = createStructuredSelector({
	settings: getSettings,
});

export const SocialsController = connect(mapStateToProps)(Socials);
