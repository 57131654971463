import * as React from 'react';
import cn from 'classnames';
import { IHeadingProps } from './heading-types';
import styles from './heading.module.scss';

export const Heading = ({
	size = 4,
	className,
	text,
	center,
	textCenter,
}: IHeadingProps) => (
	<div className={cn(
		styles['heading'],
		className,
		{
			[styles[`heading-size-${size}`]]: size,
			'jc-c': center,
			't-c': textCenter,
		},
	)}>
		{text}
	</div>
);