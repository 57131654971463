import axios from 'axios';
import { Dispatch } from 'redux';
import { API } from '@/api';

export const SET_POLICY = 'SET_POLICY';
export const setPolicy = (payload) => ({
	type: SET_POLICY,
	payload,
});

export const getPolicyFromServer = () => (dispatch: Dispatch) => axios({
	method: 'get',
	url: API.URLS.POLICY,
})
	.then((response) => dispatch(setPolicy(response.data)))
	.catch((error) => console.log('Ошибка получения политики конфиденциальности', error));
