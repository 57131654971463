import * as React from 'react';
import { connect } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { getSettingsData, updateStoreKey } from './application-actions';
import { getSettings } from './application-selectors';
import { IWithSettingsDataComponent } from './application-types';

export const withSettingsData = (WrappedComponent: React.ComponentType) => {
	class WithSettingsDataComponent extends React.PureComponent<IWithSettingsDataComponent> {
		displayName = `with ProductsDataComponent (${WrappedComponent.displayName || WrappedComponent.name})`;

		componentDidMount(): void {
			this.props.getSettingsData().then(() => {
				this.props.updateStoreKey();
			});
		}

		render() {
			if (isEmpty(this.props.settings)) {
				return null;
			}
			return (
				<WrappedComponent
					{...omit(this.props, 'updateStoreKey', 'getSettingsData')}
				/>
			);
		}
	}

	const mapStateToProps = createStructuredSelector({
		settings: getSettings,
	});

	const mapDispatchToProps = {
		getSettingsData,
		updateStoreKey,
	};

	return connect(mapStateToProps, mapDispatchToProps)(WithSettingsDataComponent);
};
