import * as React from 'react';
import { Header } from '@/modules/header';
import { Footer } from '@/modules/footer';
import { Carousel } from '@/modules/carousel';
import { Menu } from '@/modules/menu';
import { CategoriesBar } from '@/modules/categories-bar';
import { PageWrapper } from '@/components/page-wrapper';

export const HomePage = React.memo(() => (
	<React.Fragment>
		<PageWrapper>
			<Header />
			<Carousel />
			<CategoriesBar />
			<main>
				<Menu />
			</main>
		</PageWrapper>
		<Footer />
	</React.Fragment>
));
