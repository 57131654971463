export const CART_ITEMS_LOCAL_STORAGE_KEY = 'okinava-spa-cart-items';
export const CART_CLIENT_DATA_LOCAL_STORAGE_KEY = 'okinava-client-data';
export const OLDER_KEY = 'olderKey';

export enum CART_ITEM_COUNT_OPERATIONS {
	PLUS = 'plus',
	MINUS = 'minus',
	UPDATE = 'update',
}

export const DEFAULT_TITLE = 'Окинава - Суши, роллы, пицца';

export const BAD_RESPONSE = { success: false };
