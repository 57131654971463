import * as React from 'react';
import cn from 'classnames';
import { IProductLabelsProps } from './product-labels-types';
import styles from './product-labels.module.scss';

export const ProductLabels = React.memo(({ isPopular, isNew, align }: IProductLabelsProps) => (
	<React.Fragment>
		{(isPopular || isNew) && (
			<div className={cn(
				styles['labels'],
				{
					[styles[`labels--${align}`]]: align,
				},
			)}>
				{isNew && (
					<div
						className={cn(
							styles['label'],
							styles['label--new'],
							{
								[styles[`label--${align}`]]: align,
							},
						)}
						title="Новинка в меню"
					>
						НОВИНКА
					</div>
				)}
				{isPopular && (
					<div
						className={cn(
							styles['label'],
							styles['label--popular'],
							{
								[styles[`label--${align}`]]: align,
							},
						)}
						title="Товар очень популярный"
					>
						ХИТ
					</div>
				)}
			</div>
		)}
	</React.Fragment>
));
