import * as React from 'react';
import { Container } from '@/modules/aline';
import { Header } from '@/modules/header';
import { Footer } from '@/modules/footer';
import { Heading } from '@/components/heading';
import { PageWrapper } from '@/components/page-wrapper';
import { CartModule } from '@/modules/cart';
import styles from './cart-page.module.scss';

export const CartPage = React.memo(() => (
	<React.Fragment>
		<PageWrapper>
			<Header />
			<Heading
				size={3}
				text="Корзина"
				textCenter
				className="ptb-30"
			/>
			<Container>
				<div className={styles['wrapper']}>
					<CartModule />
				</div>
			</Container>
		</PageWrapper>
		<Footer />
	</React.Fragment>
));
