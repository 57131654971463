import * as React from 'react';
import cn from 'classnames';
import { Logo } from '@/components/logo';
import { CartPresenter } from '@/components/cart-presenter';
import { SearchPresenter } from '@/components/search-presenter';
import { PATHS } from '@/routes/routes-constants';
import { Navigation } from '../navigation';
import { Container, Row, Column, BarButton } from '../aline';
import { IHeaderProps } from './header-types';
import styles from './header.module.scss';

export const Header = React.memo(({
	cartSummary,
	title,
	logotype,
	redirectTo,
	headerRef,
	isHeaderFixed,
	openSidebar,
}: IHeaderProps) => (
	<header
		className={cn(
			styles['header'],
			{
				[styles['header--fixed']]: isHeaderFixed,
			},
		)}
		ref={headerRef}
	>
		<Container>
			<Row className="ai-c">
				<Column
					width={{ default: 15 }}
					className="hidden-s-u"
				>
					<BarButton onClick={openSidebar}/>
				</Column>
				<Column width={{ default: 15, s: 20, xs: 45 }}>
					<Logo
						image={logotype}
						title={title}
					/>
				</Column>
				<Column width={{ default: 60, m: 50 }} className="hidden-xs-d">
					<Navigation isHeader />
				</Column>
				<Column width={{ default: 10, m: 15, s: 10 }} textCenter className="hidden-xs-d">
					<SearchPresenter
						redirectTo={PATHS.SEARCH_PAGE}
					/>
				</Column>
				<Column
					width={{ default: 15, s: 20, xs: 40 }}
					className={cn(
						't-c',
						't-xs-r',
					)}
				>
					<CartPresenter
						cartSummary={cartSummary}
						redirectTo={redirectTo}
					/>
				</Column>
			</Row>
		</Container>
	</header>
));