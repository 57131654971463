import * as React from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowUpIcon } from '@/assets/svg/arrow-up.svg';
import { IScrollToTop } from './scroll-to-top-types';
import styles from './scroll-to-top.module.scss';

export const ScrollToTop = ({ onClick, isScrollToTopVisibility }: IScrollToTop) => (
	<div
		className={cn(
			styles['scroll-to-top'],
			{
				[styles['scroll-to-top--active']]: isScrollToTopVisibility,
			},
		)}
		onClick={onClick}
	>
		<ArrowUpIcon />
		<span className={styles['text']}>
			Наверх
		</span>
	</div>
);