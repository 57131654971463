import * as React from 'react';
import cn from 'classnames';
import { isNumber, isObject } from 'lodash';
import { IColumnProps } from './aline-types';

export const Column = ({ children, className, width, center, textCenter, hidden }: IColumnProps) => {
	const responsiveClasses: Array<string> = isObject(width)
		? Object.entries(width).map((entry) => (
			(entry[0] === 'default')
				? `col-${entry[1]}`
				:	`col-${entry[0]}-${entry[1]}`
		))
		: [];

	return (
		<div className={cn(
			className,
			{
				[`col-${width}`]: isNumber(width),
				'jc-c': center,
				't-c': textCenter,
				'hidden': hidden,
			},
			responsiveClasses,
		)}>
			{children}
		</div>
	);
};
