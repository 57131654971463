import { createSelector } from 'reselect';
import {
	getProducts,
	getCartItems,
	TProducts,
	TProduct,
	TCartItem,
} from '@/modules/application';
import { getModalData } from '@/modules/modals';
import { PRODUCT_MODAL_NAME } from './product-modal-constants';

export const getProductFromModalData = createSelector(
	getModalData(PRODUCT_MODAL_NAME),
	getProducts,
	(
		modalData: { id: string },
		products: TProducts,
	): TProduct | void => products.find((product: TProduct) => product.id === modalData?.id),
);

export const getProductItemCountInCart = createSelector(
	getCartItems,
	getProductFromModalData,
	(
		cartItems: Array<TCartItem>,
		productItem?: TProduct,
	): string | number | void => {
		const cartCountItem = cartItems?.find((cartItem: TCartItem) => cartItem.id === productItem?.id);
		return cartCountItem?.count;
	},
);
