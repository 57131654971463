import * as React from 'react';
import cn from 'classnames';
import { IYaMapCustomProps } from './ya-map-types';
import styles from './ya-map.module.scss';

export class YaMapCustom extends React.PureComponent<IYaMapCustomProps> {
	render() {
		return (
			<div
				className={cn(
					styles['YAMap'],
					styles['YAMap--custom'],
				)}
				dangerouslySetInnerHTML={{ __html: this.props.iframe }}
			/>
		);
	}
}
