import * as React from 'react';
import cn from 'classnames';
import { ReactComponent as SearchSvg } from '@/assets/svg/search.svg';
import { ISearchPresenter } from './search-presenter-types';
import styles from './search-presenter.module.scss';

export const SearchPresenter = React.memo(({ onClick, isActive, className }: ISearchPresenter) => (
	<div
		className={cn(
			styles['search'],
			{
				[styles['search--active']]: isActive,
			},
			className,
		)}
		onClick={onClick}
		title="Искать"
	>
		<SearchSvg />
		<span className={cn(
			styles['search-text'],
			'hidden-m hidden-s',
		)}>
			Искать
		</span>
	</div>
));
