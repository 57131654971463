import * as React from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { addReducer, deleteReducer } from './store-manager-actions';
import { IWithStoreManagerComponentProps } from './store-manager-types';

export const withStoreManager = (key: string) => (WrappedComponent: React.ComponentType) => {
	class WithStoreManagerComponent extends React.PureComponent<IWithStoreManagerComponentProps> {
		static displayName = `withStoreManager (${WrappedComponent.displayName || WrappedComponent.name})`;

		constructor(props) {
			super(props);
			this.props.addReducer(key);
		}

		componentWillUnmount() {
			this.props.deleteReducer(key);
		}

		render() {
			return <WrappedComponent {...omit(this.props, ['addReducer', 'deleteReducer'])} />;
		}
	}
	return connect(
		null,
		{ addReducer, deleteReducer },
	)(WithStoreManagerComponent);
};
