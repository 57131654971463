import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { getFormValues, isInvalid, getFormSyncWarnings } from 'redux-form';
import {
	getSettings,
	getClientData,
	getCartSummary,
	getCartItems,
	TClientDataStorage,
	TSettings,
	TCartItem,
	TClientDataToSave,
	TClientDataFormValues,
	TPreparedDataForCall1,
} from '@/modules/application';
import { getNext7Days, checkDay, getNextDayHours, getNextHourMinutes } from '@/utils';
import {
	CART_PERSONAL_DATA_FORM_NAME,
	CART_PERSONAL_DATA_FORM_FIELDS,
	PAYMENT_METHODS,
	DELIVERY_TIME_METHODS,
	DELIVERY_METHODS,
} from '../../cart-constants';
import { CORRECTION_MINUTES } from './cart-personal-step-constants';
import {
	TDeliveryTimeOptions,
	TDeliveryTimeOption,
	TTakeAwayTimeOptions,
} from './cart-personal-step-types';

export const getInitialValues = createSelector(
	getClientData,
	(clientData: TClientDataStorage): TClientDataStorage => ({
		...clientData,
		[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_METHOD]: DELIVERY_METHODS.DELIVERY,
		[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_TIME_METHOD]: DELIVERY_TIME_METHODS.ASAP,
		[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_TIME_METHOD]: DELIVERY_TIME_METHODS.ASAP,
		[CART_PERSONAL_DATA_FORM_FIELDS.PAYMENT_METHOD]: PAYMENT_METHODS.CARD,
		[CART_PERSONAL_DATA_FORM_FIELDS.PERSONS]: 1,
		[CART_PERSONAL_DATA_FORM_FIELDS.DATA_SAVE]: true,
	}),
);

// TODO просто = getFormValues(CART_PERSONAL_DATA_FORM_NAME)
export const getClientDataFormValues = createSelector(
	getFormValues(CART_PERSONAL_DATA_FORM_NAME),
	(formValues: TClientDataFormValues) => formValues,
);

export const getDeliveryTimeOptions = createSelector(
	getSettings,
	getFormValues(CART_PERSONAL_DATA_FORM_NAME),
	(
		settings: TSettings,
		formValues: TClientDataFormValues,
	): TDeliveryTimeOptions<TDeliveryTimeOption> => {
		const currentDate = new Date();
		const { workStartTime, workEndTime } = settings;
		const nextDays = getNext7Days();

		const days = nextDays.map((day) => ({
			value: day,
			textValue: checkDay(day),
		}));

		const hours = getNextDayHours({
			date: currentDate,
			selectedDay: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY],
			endHour: workEndTime,
			startHour: workStartTime,
		}).map((hour) => ({
			value: hour,
			textValue: hour,
		}));

		const minutes = getNextHourMinutes(
			formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR],
			CORRECTION_MINUTES,
		).map((minute) => ({
			value: minute,
			textValue: minute === 0 ? '00' : minute,
		}));

		return {
			days,
			hours: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY] ? hours : [],
			minutes: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR] ? minutes : [],
		};
	},
);

export const getTakeAwayTimeOptions = createSelector(
	getSettings,
	getFormValues(CART_PERSONAL_DATA_FORM_NAME),
	(
		settings: TSettings,
		formValues: TClientDataFormValues,
	): TTakeAwayTimeOptions<TDeliveryTimeOption> => {
		const currentDate = new Date();
		const { workStartTime, workEndTime } = settings;

		const hours = getNextDayHours({
			date: currentDate,
			selectedDay: currentDate.getDate(),
			endHour: workEndTime,
			startHour: workStartTime,
		}).map((hour) => ({
			value: hour,
			textValue: hour,
		}));

		const minutes = getNextHourMinutes(
			formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR],
			CORRECTION_MINUTES,
		).map((minute) => ({
			value: minute,
			textValue: minute === 0 ? '00' : minute,
		}));

		return {
			hours: hours || [],
			minutes: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR] ? minutes : [],
		};
	},
);

export const getClientDataToSave = createSelector(
	getFormValues(CART_PERSONAL_DATA_FORM_NAME),
	(
		formValues: TClientDataFormValues,
	): TClientDataToSave | null => {
		if (formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DATA_SAVE]) {
			return {
				[CART_PERSONAL_DATA_FORM_FIELDS.NAME]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.NAME],
				[CART_PERSONAL_DATA_FORM_FIELDS.PHONE]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.PHONE],
				[CART_PERSONAL_DATA_FORM_FIELDS.STREET]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.STREET],
				[CART_PERSONAL_DATA_FORM_FIELDS.HOME]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.HOME],
				[CART_PERSONAL_DATA_FORM_FIELDS.FLAT]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.FLAT],
				[CART_PERSONAL_DATA_FORM_FIELDS.STAGE]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.STAGE],
				[CART_PERSONAL_DATA_FORM_FIELDS.ENTRANCE]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.ENTRANCE],
				[CART_PERSONAL_DATA_FORM_FIELDS.DATA_SAVE]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DATA_SAVE],
				[CART_PERSONAL_DATA_FORM_FIELDS.FROM_UBI]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.FROM_UBI],
			};
		}
		return null;
	},
);

export const getPreparedDataForServer = createSelector(
	getCartSummary,
	getCartItems,
	getFormValues(CART_PERSONAL_DATA_FORM_NAME),
	(
		cartSummary: number,
		cartItems: Array<TCartItem>,
		formValues: TClientDataFormValues,
	): TPreparedDataForCall1 => {
		let personalData = {};

		// Стандартные поля
		personalData = {
			[CART_PERSONAL_DATA_FORM_FIELDS.NAME]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.NAME],
			[CART_PERSONAL_DATA_FORM_FIELDS.PHONE]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.PHONE],
			[CART_PERSONAL_DATA_FORM_FIELDS.PERSONS]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.PERSONS],
			[CART_PERSONAL_DATA_FORM_FIELDS.COMMENT]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.COMMENT],
			[CART_PERSONAL_DATA_FORM_FIELDS.PAYMENT_METHOD]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.PAYMENT_METHOD],
			[CART_PERSONAL_DATA_FORM_FIELDS.FROM_UBI]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.FROM_UBI],
			[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_METHOD]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_METHOD],
		};

		// Если доставка
		if (formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_METHOD] === DELIVERY_METHODS.DELIVERY) {
			personalData = {
				...personalData,
				[CART_PERSONAL_DATA_FORM_FIELDS.STREET]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.STREET],
				[CART_PERSONAL_DATA_FORM_FIELDS.HOME]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.HOME],
				[CART_PERSONAL_DATA_FORM_FIELDS.FLAT]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.FLAT],
				[CART_PERSONAL_DATA_FORM_FIELDS.ENTRANCE]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.ENTRANCE],
				[CART_PERSONAL_DATA_FORM_FIELDS.STAGE]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.STAGE],
				[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_TIME_METHOD]: formValues?.[
					CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_TIME_METHOD
				],
			};

			// Если по времени
			if (formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_TIME_METHOD] === DELIVERY_TIME_METHODS.AT_TIME) {
				personalData = {
					...personalData,
					[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY],
					[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR],
					[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_MINUTE]: formValues?.[
						CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_MINUTE
					],
				};
			}
		}

		// Если самовывоз
		if (formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_METHOD] === DELIVERY_METHODS.TAKEAWAY) {
			personalData = {
				...personalData,
				[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_TIME_METHOD]: formValues?.[
					CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_TIME_METHOD
					],
			};

			// Если по времени
			if (formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_TIME_METHOD] === DELIVERY_TIME_METHODS.AT_TIME) {
				personalData = {
					...personalData,
					[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR],
					[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_MINUTE]: formValues?.[
						CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_MINUTE
					],
				};
			}
		}

		// Если наличными
		if (formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.PAYMENT_METHOD] === PAYMENT_METHODS.CASH) {
			personalData = {
				...personalData,
				[CART_PERSONAL_DATA_FORM_FIELDS.MONEY_NO_CHANGE]: formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.MONEY_NO_CHANGE],
			};

			// Если со сдачей
			if (!formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.MONEY_NO_CHANGE]) {
				personalData = {
					...personalData,
					[CART_PERSONAL_DATA_FORM_FIELDS.MONEY_CHANGE_FROM]: formValues?.[
						CART_PERSONAL_DATA_FORM_FIELDS.MONEY_CHANGE_FROM
					],
				};
			}
		}

		return <TPreparedDataForCall1>{
			personalData,
			cartSummary,
			cartItems,
		};
	},
);

export const getPreparedCartItemsForCall2 = createSelector(
	getPreparedDataForServer,
	getCartItems,
	(
		preparedData: TPreparedDataForCall1,
		cartItems: Array<TCartItem>,
	) => {
		const preparedForCall2Items = cartItems.map((cartItem: TCartItem) => ({
			id: cartItem.iikoId,
			name: cartItem.name,
			amount: cartItem.count,
			sum: cartItem.summary,
			code: cartItem.iikoCode,
		}));
		// TODO perspective при изменении полей формы (просто ввести в любое поле) идет перерасчет этого селектора
		return {
			...preparedData,
			cartItems: preparedForCall2Items,
		};
	},
);

export const getMinDeliveryAmount = createSelector(
	getSettings,
	getFormValues(CART_PERSONAL_DATA_FORM_NAME),
	(
		settings: TSettings,
		formValues: TClientDataFormValues,
	): number => {
		const { minDeliveryAmount, minDeliveryAmountUbi } = settings;
		const isFromUbi = formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.FROM_UBI];

		return isFromUbi ? minDeliveryAmountUbi : minDeliveryAmount;
	},
);

export const getIsTakeaway = createSelector(
	getFormValues(CART_PERSONAL_DATA_FORM_NAME),
	(
		formValues?: TClientDataFormValues,
	): boolean => formValues?.deliveryMethod === DELIVERY_METHODS.TAKEAWAY,
);

export const getIsProperOrderSum = createSelector(
	getMinDeliveryAmount,
	getCartSummary,
	(
		minDeliveryAmount: number,
		cartSummary: number,
	): boolean => cartSummary >= minDeliveryAmount,
);

export const getIsInWorkingTime = createSelector(
	getSettings,
	(
		settings: TSettings,
	): boolean => {
		const { workStartTime, workEndTime, serverDate } = settings;
		const {
			hour: currentHour = new Date().getHours(),
			minute: currentMinute = new Date().getMinutes(),
		} = serverDate || {};

		const workStartTimeInMinutes = Number(workStartTime) * 60;
		const workEndTimeInMinutes = Number(workEndTime) * 60;

		const currentTimeInMinutes = (Number(currentHour) * 60) + Number(currentMinute);

		return (currentTimeInMinutes > workStartTimeInMinutes) && (currentTimeInMinutes < workEndTimeInMinutes);
	},
);

// В этот селектор, добавлять общие ошибки формы
// (могут быть не связаны с полями формы), будут отображаться в самом низу где кнопка оформить заказ
export const getOrderErrors = createSelector(
	getMinDeliveryAmount,
	getIsProperOrderSum,
	isInvalid(CART_PERSONAL_DATA_FORM_NAME),
	getSettings,
	getIsInWorkingTime,
	getIsTakeaway,
	(
		minDeliveryAmount: number,
		isProperOrderSum: boolean,
		isFormInvalid: boolean,
		settings: TSettings,
		isInWorkingTime: boolean,
		isTakeaway: boolean,
	): Array<string> | null => {
		const errors = [];
		const { workStartTime, workEndTime } = settings;

		if (!isProperOrderSum && !isTakeaway) {
			errors.push(`Минимальная сумма заказа: ${minDeliveryAmount} рублей`);
		}
		if (isFormInvalid) {
			errors.push('Проверьте правильность заполнения полей');
		}
		if (!isInWorkingTime) {
			errors.push(`Сейчас суши-бар закрыт, мы работаем с ${workStartTime}:00 до ${workEndTime}:00`);
		}
		return errors;
	},
);

// Опциональный - для варнингов (возможно не понадобится)
export const getOrderWarnings = createSelector(
	getSettings,
	getIsInWorkingTime,
	getFormSyncWarnings(CART_PERSONAL_DATA_FORM_NAME),
	(
		settings: TSettings,
		isInWorkingTime: boolean,
		formWarnings,
	): Array<string> | null => {
		const warnings = [];

		if (!isEmpty(formWarnings)) {
			warnings.push('Возможно некоторые поля заполнены некорректно');
		}

		return warnings;
	},
);
