import * as React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withProductsData, withTitles } from '@/modules/application';
import { MenuPage } from './menu-page';
import { IMenuPageControllerComponentProps } from './menu-page-types';

export class MenuPageControllerComponent extends React.PureComponent<IMenuPageControllerComponentProps> {
	render() {
		return (
			<MenuPage />
		);
	}
}

export const MenuPageController = compose(
	withTitles,
	withRouter,
	withProductsData,
)(MenuPageControllerComponent);
