import * as React from 'react';
import { Header } from '@/modules/header';
import { Footer } from '@/modules/footer';
import { Heading } from '@/components/heading';
import { Search } from '@/components/search';
import { PageWrapper } from '@/components/page-wrapper';
import { SearchResults } from '@/modules/search-results';
import { Container } from '@/modules/aline';
import { SEARCH_FIELD_NAMES } from './search-page-constants';
import { ISearchPageProps } from './search-page-types';

export const SearchPage = React.memo(({ products, headerReference }: ISearchPageProps) => (
	<React.Fragment>
		<PageWrapper
			style={{ minHeight: window.innerHeight + headerReference?.current?.offsetHeight }}
		>
			<Header />
			<Heading
				size={3}
				text="Поиск"
				textCenter
				className="ptb-30"
			/>
			<main>
				<Container>
					<Search
						fieldName={SEARCH_FIELD_NAMES.SEARCH}
					/>
					<SearchResults products={products} />
				</Container>
			</main>
		</PageWrapper>
		<Footer />
	</React.Fragment>
));
