import * as React from 'react';
import cn from 'classnames';
import ProgressiveImage from 'react-progressive-image';
import { Button, Column, Row } from '@/modules/aline';
import { ProductCounter } from '@/modules/product-counter';
import { Heading } from '@/components/heading';
import { TProduct } from '@/modules/application';
import { Product, IMAGE_PRELOADER } from '@/modules/product';
import { CaloriesTable } from '@/components/calories-table';
import { ProductLabels } from '@/components/product-labels';
import { IProductCardProps } from './product-card-types';
import styles from './product-card.module.scss';

const productColumnWidth = {
	default: 20,
	l: 25,
	s: 33,
	xs: 100,
};

export const ProductCard = React.memo(({
	productItem,
	productItemCartCount,
	addToCart,
	proposals,
}: IProductCardProps) => productItem && (
	<React.Fragment>
		<div className={styles['product-card-block']}>
			<Row>
				<Column width={{ default: 50, xs: 100 }}>
					<div className={styles['image-block']}>
						<ProductLabels
							isNew={productItem?.isNew}
							isPopular={productItem?.isPopular}
							align="left"
						/>
						<ProgressiveImage
							src={productItem?.image}
							placeholder={IMAGE_PRELOADER}
						>
							{(src, isLoading) => (
								<div
									className={cn(
										styles['image'],
										{
											[styles['image--loading']]: isLoading,
										},
									)}
									style={{ backgroundImage: `url(${src})` }}
								/>
							)}
						</ProgressiveImage>
					</div>
				</Column>
				<Column width={{ default: 50, xs: 100 }}>
					<div className={styles['product-data']}>
						{productItem?.categoryName && (
							<div className={cn(
								styles['row'],
								styles['category'],
							)}>
								<div className={styles['category-name']}>
									{productItem?.categoryName}
								</div>
							</div>
						)}
						<div className={cn(
							styles['row'],
							styles['name'],
						)}>
							{productItem?.name}
						</div>
						<div className={cn(
							styles['row'],
							styles['ingredients'],
						)}>
							{productItem?.ingredients}
						</div>
						<div className={cn(
							styles['row'],
							styles['quantity'],
						)}>
							{
								(!!productItem?.quantity && `${productItem?.quantity} шт.`)
								|| (!!productItem?.productPortionSize && `${productItem?.productPortionSize} см`)
								|| (!!productItem?.productVolume && `${productItem?.productVolume} мл`)
							}
						</div>
						<div className={cn(
							styles['row'],
							styles['price-block'],
						)}>
							<Row alignCenter>
								<Column width={50}>
									<div className={styles['price']}>
										{productItem?.price} руб.
									</div>
								</Column>
								<Column width={50}>
									<div className={styles['product-actions']}>
										{productItemCartCount ? (
											<ProductCounter
												className={styles['counter']}
												id={productItem?.id}
												value={productItemCartCount}
												width={65}
											/>
										) : (
											<Button
												onClick={(event: React.MouseEvent) => addToCart(event, productItem)}
												size="small"
												color="black"
												border={3}
												bold
											>
												Добавить
											</Button>
										)}
									</div>
								</Column>
							</Row>
						</div>
						<div className={cn(
							styles['row'],
							styles['article'],
						)}>
							Артикул: {productItem?.id}
						</div>
						{productItem?.isCaloriesTableVisible && (
							<div className={cn(
								styles['row'],
								styles['calories-table'],
							)}>
								<CaloriesTable
									calories={productItem?.calories}
									productPortionWeight={productItem?.productPortionWeight}
								/>
							</div>
						)}
					</div>
				</Column>
			</Row>
		</div>
		{!!proposals?.length && (
			<div className={styles['proposals']}>
				<Heading
					size={4}
					text="Предлагаем попробовать"
					textCenter
					className="ptb-10"
				/>
				<Row center className="ai-str">
					{!!(proposals.length) && proposals.map((productItem: TProduct) => (
						<Column
							width={productColumnWidth}
							key={productItem?.id}
						>
							<Product
								productItem={productItem}
							/>
						</Column>
					))}
				</Row>
			</div>
		)}
	</React.Fragment>
));
