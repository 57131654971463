import { combineReducers } from 'redux';
import { omit } from 'lodash';
import { ADD_REDUCER, DELETE_REDUCER } from './store-manager-constants';
import { TStaticReducers, TDynamicReducers } from './store-manager-types';

export const storeManager = (staticReducers: TStaticReducers, dynamicReducers: TDynamicReducers) => {
	const includedReducers = {};
	let actualReducer = combineReducers(staticReducers);

	const updateActualReducer = () => {
		actualReducer = combineReducers({
			...staticReducers,
			...includedReducers,
		});
	};

	return (state, action) => {
		const { type, payload } = action;

		if (type === ADD_REDUCER) {
			includedReducers[payload] = dynamicReducers[payload];
			updateActualReducer();
		}

		if (type === DELETE_REDUCER) {
			delete includedReducers[payload];
			updateActualReducer();
			return actualReducer(omit(state, payload), action);
		}

		return actualReducer(state, action);
	};
};