import * as React from 'react';
import SlickCarousel from 'react-slick';
import { TCarouselItem } from '@/modules/carousel';
import { ICarouselProps } from './carousel-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel-custom-styles.scss';

const sliderSettings = {
	dots: true,
	infinite: true,
	speed: 400,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	className: 'carousel-custom',
	autoplay: true,
};

export const Carousel = React.memo(({ carouselItems, isSliderAutoplay, sliderDelay }: ICarouselProps) => (
	<SlickCarousel
		{...sliderSettings}
		autoplay={isSliderAutoplay}
		autoplaySpeed={sliderDelay * 1000}
	>
		{!!carouselItems.length && carouselItems.map(({ id, image, name }: TCarouselItem) => (
			<div
				className="carousel-item"
				key={id}
			>
				<img
					src={image}
					alt={name}
				/>
			</div>
		))}
	</SlickCarousel>
));
