import * as React from 'react';
import cn from 'classnames';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { PATHS } from '@/routes/routes-constants';
import { Button, Checkbox, SwitchTab } from '@/modules/aline';
import { cartDataStepPath } from '@/routes/routes-utils';
import {
	TextInput,
	PhoneInput,
	SelectInput,
	NumberInput,
	TextareaInput,
} from '@/components/inputs';
import {
	CART_PERSONAL_DATA_FORM_FIELDS,
	PERSONS_VALUES,
	PAYMENT_METHODS_VALUES,
	PAYMENT_METHODS,
} from '../../cart-constants';
import { onlyDeliveryTab } from './cart-personal-step-constants';
import { ICartPersonalStepProps } from './cart-personal-step-types';
import styles from './cart-personal-step.module.scss';

export const CartPersonalStep = React.memo(({
	address,
	onDataSend,
	formValues,
	deliveryTimeOptions,
	takeAwayTimeOptions,
	invalid,
	orderErrors,
	orderWarnings,
	isOrderProcessing,
}: ICartPersonalStepProps) => (
	<>
		<div className={styles['row']}>
			{/* Имя */}
			<Field
				name={CART_PERSONAL_DATA_FORM_FIELDS.NAME}
				component={TextInput}
				label="Ваше имя"
			/>
		</div>
		<div className={styles['row']}>
			{/* Контактный телефон */}
			<Field
				name={CART_PERSONAL_DATA_FORM_FIELDS.PHONE}
				component={PhoneInput}
				label="Контактный телефон"
			/>
		</div>

		<div className={cn(
			styles['row'],
			'mt-50 mt-xs-20',
		)}>
			{/* Табы, самовывоз или доставка (с контентом) */}
			<Field
				name={CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_METHOD}
				label="Выберите способ получения заказа"
				component={SwitchTab}
				tabs={onlyDeliveryTab}
				address={address}
				formValues={formValues}
				takeAwayTimeOptions={takeAwayTimeOptions}
				deliveryTimeOptions={deliveryTimeOptions}
			/>
		</div>

		<div className={styles['row']}>
			{/* Селект на количество приборов */}
			{/*TODO переименовать persons тут и на беке, неправльно названо - это кол-во приборов*/}
			<Field
				name={CART_PERSONAL_DATA_FORM_FIELDS.PERSONS}
				component={SelectInput}
				label="Количество приборов"
				options={PERSONS_VALUES}
				disabledValue="Выберите количество приборов"
				defaultValue={1}
			/>
		</div>

		<div className={styles['row']}>
			{/* Комментарий */}
			<Field
				name={CART_PERSONAL_DATA_FORM_FIELDS.COMMENT}
				component={TextareaInput}
				label="Комментарий"
			/>
		</div>

		<div className={styles['row']}>
			{/* Таб на способы оплаты (без контента) */}
			<Field
				name={CART_PERSONAL_DATA_FORM_FIELDS.PAYMENT_METHOD}
				label="Выберите способ оплаты"
				component={SwitchTab}
				tabs={PAYMENT_METHODS_VALUES}
				isSimple
			/>
		</div>
		{formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.PAYMENT_METHOD] === PAYMENT_METHODS.CASH && (
			<React.Fragment>
				{!formValues?.[CART_PERSONAL_DATA_FORM_FIELDS.MONEY_NO_CHANGE] && (
					<div className={styles['row']}>
						{/* Сдада с */}
						<Field
							name={CART_PERSONAL_DATA_FORM_FIELDS.MONEY_CHANGE_FROM}
							component={NumberInput}
							label="Сдача с"
						/>
					</div>
				)}
				<div className={styles['row']}>
					{/* Флаг, со сдайчей или без */}
					<Field
						name={CART_PERSONAL_DATA_FORM_FIELDS.MONEY_NO_CHANGE}
						component={Checkbox}
						label="Без сдачи"
						type="checkbox"
					/>
				</div>
			</React.Fragment>
		)}
		<div className={styles['row']}>
			{/* Флаг, сохранить данные для следующего входа */}
			<Field
				name={CART_PERSONAL_DATA_FORM_FIELDS.DATA_SAVE}
				component={Checkbox}
				label="Сохранить данные для следующего входа"
				type="checkbox"
			/>
		</div>

		{/*TODO (perspective) можно объединить условие в одну переменную или в селектор
		(имеется ввиду можно упростить, получать варнинги и эрроры из одного места*/}
		{!!orderErrors.length && (
			<div className={styles['errors']}>
				{orderErrors.map((error) => (
					<div
						key={error}
						className={styles['error']}
					>
						{error}
					</div>
				))}
			</div>
		)}
		{!!orderWarnings.length && (
			<div className={styles['warnings']}>
				{orderWarnings.map((warning) => (
					<div
						key={warning}
						className={styles['warning']}
					>
						{warning}
					</div>
				))}
			</div>
		)}

		<div className={styles['policy-block']}>
			Нажимая кнопку «Оформить заказ», я даю свое согласие на обработку моих персональных данных,
			в соответствии с Федеральным законом от 27.07.2006 года №152-Ф3 «О персональных данных»,
			на условиях и для целей, определенных в
			<Link to={PATHS.POLICY_PAGE}>
				Cогласии на обработку персональных данных
			</Link>
		</div>

		<div className={styles['actions']}>
			<Link to={cartDataStepPath}>
				<Button
					size="small"
					color="black"
					border={3}
					bold
				>
					Назад
				</Button>
			</Link>
			<Button
				onClick={onDataSend}
				size="small"
				color="black"
				border={3}
				bold
				disabled={invalid || !!orderErrors.length}
				isLoading={isOrderProcessing}
				loadingText={'Отправляем заказ'}
			>
				Оформить заказ
			</Button>
		</div>
	</>
));
