import { createSelector } from 'reselect';
import { STORE_KEYS } from '@/store/store-constants';
import { TPolicyPageState } from './policy-page-types';

const getPolicyPageState = (state) => state[STORE_KEYS.POLICY_PAGE];

export const getPolicy = createSelector(
	getPolicyPageState,
	(policyPageState?: TPolicyPageState) => policyPageState?.policy,
);
