import * as React from 'react';
import cn from 'classnames';
import { INumberInputProps } from './number-input-types';
import styles from './number-input.module.scss';

export const NumberInput = ({
	label,
	input,
	meta: { touched, error, warning },
}: INumberInputProps) => (
	<React.Fragment>
		{label && (
			<label className={styles['label']}>
				{label}
			</label>
		)}
		<div className={cn(
			styles['wrapper'],
			{
				[styles['wrapper--error']]: error,
				[styles['wrapper--warning']]: warning,
				// [styles['wrapper--welldone']]: !error && !warning,
			},
		)}>
			<input
				className={styles['input']}
				{...input}
				name={input.name}
				onChange={input.onChange}
				value={input.value}
				type="number"
			/>
		</div>
		{error && touched && (
			<div className={styles['error']}>
				{error}
			</div>
		)}
		{!error && warning && touched && (
			<div className={styles['error']}>
				{warning}
			</div>
		)}
	</React.Fragment>
);
