import * as React from 'react';
import { Modal, Button } from '../../aline';
import { IShowSimpleModalProps } from './simple-modal-types';
import styles from './simple-modal.module.scss';

export const SimpleModal = React.memo(({
	header,
	message,
	noButton,
	yesButton,
	isOpen,
	handleOk,
	handleCancel,
}: IShowSimpleModalProps) => {
	const noButtonTitle = noButton?.title;
	const yesButtonTitle = yesButton?.title || 'Ок';

	return (
		<Modal
			isOpen={isOpen}
			size="tiny"
			closeModal={noButtonTitle ? handleCancel : handleOk}
			className={styles['simple-modal']}
		>
			<div className={styles['top-content']}>
				{header && (
					<div className={styles['header']}>
						{header}
					</div>
				)}
				<div className={styles['message']}>
					<div className={styles['message-text']}>
						{message}
					</div>
				</div>
			</div>
			<div className={styles['action-buttons']}>
				<div className={styles['action-button']}>
					{noButtonTitle && (
						<Button
							onClick={handleCancel}
							size="small"
							color="red"
							border={3}
							bold
						>
							{noButtonTitle}
						</Button>
					)}
				</div>
				<div className={styles['action-button']}>
					<Button
						onClick={handleOk}
						size="small"
						color="black"
						border={3}
						bold
					>
						{yesButtonTitle}
					</Button>
				</div>
			</div>
		</Modal>
	);
});
