import * as React from 'react';
import { ISearchProps } from './search-types';
import styles from './search.module.scss';

export const Search = React.memo(({ input: { name, onChange, value } }: ISearchProps) => (
	<div className={styles['search-input']}>
		<input
			value={value}
			name={name}
			onChange={onChange}
			type="text"
			placeholder="..."
		/>
	</div>
));
