import axios from 'axios';
import { RefObject } from 'react';
import { Dispatch } from 'redux';
import { API } from '@/api';
import {
	TProduct,
	TPreparedDataForCall1,
	TPreparedDataForCall2,
	TCallsResponse,
	TSettings,
	TCategories,
	TProducts,
	TTitleEntity,
	TGetState,
	TClientDataToSave,
} from './application-types';
import { cartItemsStorage } from './cart-storage';
import { CART_ITEM_COUNT_OPERATIONS, BAD_RESPONSE } from './application-constants';
import { getSettings } from './application-selectors';
import { clientDataStorage } from './client-data-storage';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const setCategories = (payload: TCategories) => ({
	type: SET_CATEGORIES,
	payload,
});

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const setProducts = (payload: TProducts) => ({
	type: SET_PRODUCTS,
	payload,
});

export const SET_TITLES = 'SET_TITLES';
export const setTitles = (payload: Array<TTitleEntity>) => ({
	type: SET_TITLES,
	payload,
});

export const SET_CATEGORIES_REFERENCE = 'SET_CATEGORIES_REFERENCE';
export const setCategoriesReference = (payload: {
	categoryId: string | number,
	categoryRef: RefObject<any>,
}) => ({
	type: SET_CATEGORIES_REFERENCE,
	payload,
});

export const SET_HEADER_REFERENCE = 'SET_HEADER_REFERENCE';
export const setHeaderReference = (payload: RefObject<any>) => ({
	type: SET_HEADER_REFERENCE,
	payload,
});

export const SET_SETTINGS = 'SET_SETTINGS';
export const setSettings = (payload: TSettings) => ({
	type: SET_SETTINGS,
	payload,
});

export const UPDATE_CLIENT_DATA = 'UPDATE_CLIENT_DATA';
export const updateClientData = () => ({
	type: UPDATE_CLIENT_DATA,
});

export const getCategoriesData = () => (dispatch: Dispatch) => {
	return axios({
		method: 'get',
		url: API.URLS.CATEGORIES,
	})
		.then((response) => dispatch(setCategories(response.data)))
		.catch((error) => console.log('Ошибка получения категорий', error));
};

export const getProductsData = () => (dispatch: Dispatch) => axios({
	method: 'get',
	url: API.URLS.PRODUCTS,
})
	.then((response) => dispatch(setProducts(response.data)))
	.catch((error) => console.log('Ошибка получения продуктов', error));

export const getSettingsData = () => (dispatch: Dispatch) => axios({
	method: 'get',
	url: API.URLS.SETTINGS,
})
	.then((response) => dispatch(setSettings(response.data)))
	.catch((error) => console.log('Ошибка получения настроек', error));

export const getTitlesData = () => (dispatch: Dispatch) => axios({
	method: 'get',
	url: API.URLS.TITLES,
})
	.then((response) => dispatch(setTitles(response.data)))
	.catch((error) => console.log('Ошибка получения заголовков страниц', error));

/* CART ACTIONS (MOVED FROM CART MODULE) */
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS';
export const updateCartItems = () => ({
	type: UPDATE_CART_ITEMS,
});

export const updateStoreKey = () => async (dispatch: Dispatch, getState: TGetState) => {
	const state = getState();
	const settings: TSettings = getSettings(state);
	const { storeKey } = settings;
	cartItemsStorage.storeKey = storeKey;
	dispatch(updateCartItems());
};

export const addCartItemToStorage = (productItem: TProduct) => async (
	dispatch: Dispatch,
) => {
	const cartItems = [ ...cartItemsStorage.fetch() ];
	const cartItemForAddIndex = cartItems.findIndex((cartItem: TProduct) => cartItem.id === productItem.id);
	// TODO (perspective) кейс с -1 мне не нравитсяб очень топорно (perspective)
	if (cartItemForAddIndex !== -1) {
		const cartItem = cartItems[cartItemForAddIndex];
		cartItem.count = cartItem.count + 1;
		cartItem.summary = cartItem.price * cartItem.count;
		// TODO (perspective) вычисления отрефачить
	} else {
		cartItems.push({
			...productItem,
			count: 1,
			summary: productItem.price,
		});
	}
	await cartItemsStorage.save(cartItems);
	dispatch(updateCartItems());
};

export const deleteCartItemFromStorage = (id: string | number) => async (
	dispatch: Dispatch,
) => {
	const cartItems = [ ...cartItemsStorage.fetch() ];
	const cartItemForDeleteIndex = cartItems.findIndex((cartItem: TProduct) => cartItem.id === id);
	cartItems.splice(cartItemForDeleteIndex, 1);
	await cartItemsStorage.save(cartItems);
	dispatch(updateCartItems());
};

export const changeCartItemCount = (id: string | number, operation: string, value: string) => async (
	dispatch: Dispatch,
) => {
	const cartItems = [ ...cartItemsStorage.fetch() ];
	const cartItemForDeleteIndex = cartItems.findIndex((cartItem: TProduct) => cartItem.id === id);
	const cartItem = cartItems[cartItemForDeleteIndex];
	/*if (operation === CART_ITEM_COUNT_OPERATIONS.MINUS && cartItem.count === 1) {
		cartItems.splice(cartItemForDeleteIndex, 1);
	}*/
	if (operation === CART_ITEM_COUNT_OPERATIONS.PLUS) {
		cartItem.count = cartItem.count + 1;
		cartItem.summary = cartItem.price * cartItem.count;
	}
	if (operation === CART_ITEM_COUNT_OPERATIONS.MINUS && cartItem.count > 1) {
		cartItem.count = cartItem.count - 1;
		cartItem.summary = cartItem.price * cartItem.count;
	}
	if (operation === CART_ITEM_COUNT_OPERATIONS.UPDATE && Number(value) > 0) {
		cartItem.count = Number(value);
		cartItem.summary = cartItem.price * cartItem.count;
	}
	await cartItemsStorage.save(cartItems);
	dispatch(updateCartItems());
};

export const clearCartData = () => (dispatch: Dispatch) => {
	cartItemsStorage.clear();
	dispatch(updateCartItems());
};
/* END CART ACTIONS (MOVED FROM CART MODULE) */

export const setClientDataToStorage = (clientDataToSave: TClientDataToSave) => async (dispatch: Dispatch) => {
	await clientDataStorage.save(clientDataToSave);
	dispatch(updateClientData());
};

export const removeClientDataFromStorage = () => async (dispatch: Dispatch) => {
	await clientDataStorage.clear();
	dispatch(updateClientData());
};

export const ORDER_PROCESSING = 'ORDER_PROCESSING';
export const orderProcessing = (payload: boolean) => ({
	type: ORDER_PROCESSING,
	payload,
});

export const orderCall1 = async (dataForCall1: TPreparedDataForCall1): Promise<TCallsResponse | undefined> => {
	// CALL1 (Order for site)
	try {
		const { data: responseDataFromCall1, status: responseStatusFromCall1 } = await axios({
			method: 'post',
			url: API.URLS.ORDER_CALL1,
			data: dataForCall1,
		});

		if (!responseDataFromCall1?.success || responseStatusFromCall1 !== 200) {
			console.log('Ошибка создания заказа при CALL1');
			return;
		}

		return responseDataFromCall1;
	} catch (error) {
		console.log('Ошибка создания заказа при CALL1', error);
		return;
	}
};

export const orderCall2 = async (dataForCall2: TPreparedDataForCall2): Promise<TCallsResponse | undefined> => {
	// CALL2 (Order for iiko)
	try {
		const { data: responseDataFromCall2, status: responseStatusFromCall2 } = await axios({
			method: 'post',
			url: API.URLS.ORDER_CALL2,
			data: dataForCall2,
		});

		if (!responseDataFromCall2?.success || responseStatusFromCall2 !== 200) {
			console.log('Ошибка создания заказа при CALL2');
			return;
		}

		return responseDataFromCall2;
	} catch (error) {
		console.log('Ошибка создания заказа при CALL2', error);
		return;
	}
};

export const createOrder = (
	dataForCall1: TPreparedDataForCall1,
	dataForCall2: TPreparedDataForCall2,
) => async (dispatch: Dispatch) => {
	dispatch(orderProcessing(true));
	try {
		const responseCall1 = await orderCall1(dataForCall1);
		if (!responseCall1) {
			return BAD_RESPONSE;
		}

		const responseCall2 = await orderCall2(dataForCall2);
		if (!responseCall2) {
			return responseCall1;
		}

		return responseCall2;
	} catch (e) {
		return BAD_RESPONSE;
	} finally {
		dispatch(orderProcessing(false));
	}
};
