import { policyStub } from '@/stubs';
import { SET_POLICY } from './policy-page-actions';

const policyPageState = {
	policy: process.env.NODE_ENV === 'development' ? policyStub : {},
};

export const policyPageReducer = (state = policyPageState, { type, payload }) => {
	switch (type) {
	case SET_POLICY:
		return {
			...state,
			policy: payload,
		};
	default:
		return state;
	}
};
