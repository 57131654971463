import * as React from 'react';
import { history } from '@/history';
import { NavigationHeader } from './navigation-header';
import { NavigationFooter } from './navigation-footer';
import { NavigationSidebar } from './navigation-sidebar';
import { NAVIGATION_MENU_ITEMS } from './navigation-constants';
import { INavigationControllerComponent } from './navigation-types';

export class NavigationControllerComponent extends React.PureComponent<INavigationControllerComponent> {
	sidebarMenuClickHandler = (slug: string) => {
		this.props.closeSidebar();
		history.push(slug);
	};

	render() {
		const { isFooter, isHeader, isSidebar } = this.props;
		if (isHeader) {
			return (
				<NavigationHeader
					menuItems={NAVIGATION_MENU_ITEMS}
				/>
			);
		}
		if (isFooter) {
			return (
				<NavigationFooter
					menuItems={NAVIGATION_MENU_ITEMS}
				/>
			);
		}
		if (isSidebar) {
			return (
				<NavigationSidebar
					menuItems={NAVIGATION_MENU_ITEMS}
					onMenuClick={this.sidebarMenuClickHandler}
				/>
			);
		}
		return null;
	}
}

export const NavigationController = NavigationControllerComponent;
