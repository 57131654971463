import { CART_ITEMS_LOCAL_STORAGE_KEY, OLDER_KEY } from './application-constants';

export const cartItemsStorage = {
	key: CART_ITEMS_LOCAL_STORAGE_KEY,
	get storeKey() {
		return this.key;
	},
	set storeKey(value: string) {
		if (value) {
			if (value !== this.olderKey) {
				localStorage.removeItem(this.olderKey);
			}
			this.key = value;
			localStorage.setItem(OLDER_KEY, this.storeKey);
		}
	},
	get olderKey() {
		return localStorage.getItem(OLDER_KEY);
	},
	fetch() {
		return JSON.parse(localStorage.getItem(this.storeKey) || '[]');
	},
	async save(cartItems) {
		await localStorage.setItem(this.storeKey, JSON.stringify(cartItems));
	},
	clear() {
		localStorage.removeItem(this.storeKey);
	},
};
