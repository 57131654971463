import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container } from '@/modules/aline';
import { getStructuredCategoriesWithProducts } from '@/modules/application';
import { Menu } from './menu';
import { IMenuControllerComponentProps } from './menu-types';

export class MenuControllerComponent extends React.PureComponent<IMenuControllerComponentProps> {
	render() {
		return (
			<Container>
				<Menu
					categories={this.props.categories}
				/>
			</Container>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	categories: getStructuredCategoriesWithProducts,
});

export const MenuController = connect(
	mapStateToProps,
)(MenuControllerComponent);
