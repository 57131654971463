import {
	UPDATE_CART_ITEMS,
	UPDATE_CLIENT_DATA,
} from '../application-actions';
import { cartItemsStorage } from '../cart-storage';
import { clientDataStorage } from '../client-data-storage';
import { ICartState } from '../application-types';
import { ORDER_PROCESSING } from '../application-actions';

export const cartState: ICartState = {
	cartItems: cartItemsStorage.fetch(),
	clientData: clientDataStorage.fetch(),
	summary: 0,
	emptyCartAttentionText: 'Ваша корзина пуста. Для заказа, пожалуйста, перейдите в меню.',
	orderProcessing: false,
};

export const cartReducer = (state: ICartState = cartState, { type, payload }) => {
	switch (type) {
	case UPDATE_CART_ITEMS:
		return {
			...state,
			cartItems: cartItemsStorage.fetch(),
		};
	case UPDATE_CLIENT_DATA:
		return {
			...state,
			clientData: clientDataStorage.fetch(),
		};
	case ORDER_PROCESSING:
		return {
			...state,
			orderProcessing: payload,
		};
	default:
		return state;
	}
};