import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getSettings } from '@/modules/application';
import { getSidebarVisibility } from './sidebar-selectors';
import { closeSidebar } from './sidebar-actions';
import { ISidebarControllerComponentProps } from './sidebar-types';
import { Sidebar } from './sidebar';

export class SidebarControllerComponent extends React.PureComponent<ISidebarControllerComponentProps> {
	handleCloseSidebar = () => {
		this.props.closeSidebar();
	};

	render() {
		const {
			settings: {
				logotype,
				phone1,
				phone2,
			},
			visibility,
		} = this.props;
		return (
			<Sidebar
				isOpen={visibility}
				closeSidebar={this.handleCloseSidebar}
				logotype={logotype}
				phone1={phone1}
				phone2={phone2}
			/>
		);
	}

}

const mapStateFromProps = createStructuredSelector({
	visibility: getSidebarVisibility,
	settings: getSettings,
});

const mapDispatchToProps = {
	closeSidebar,
};

export const SidebarController = connect(mapStateFromProps, mapDispatchToProps)(SidebarControllerComponent);
