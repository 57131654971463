import * as React from 'react';
import cn from 'classnames';
import { ReactComponent as CheckIcon } from '@/assets/svg/check.svg';
import { ICheckboxProps } from './checkbox-types';
import styles from './checkbox.module.scss';

export const Checkbox = ({ label, input: { onChange, value } }: ICheckboxProps) => {
	const onCheck = () => {
		onChange(!value);
	};

	return (
		<div
			className={styles['checkbox-wrapper']}
			onClick={onCheck}
		>
			<div
				className={cn(
					styles['checkbox'],
					{
						[styles['checkbox-checked']]: value,
					},
				)}
			>
				<div className={styles['icon']}>
					<CheckIcon />
				</div>
			</div>
			{label && (
				<div
					className={styles['label']}
				>
					{label}
				</div>
			)}
		</div>
	);
};
