import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
	withModals,
	getModalVisibility,
	closeModalAction,
} from '@/modules/modals';
import {
	addCartItemToStorage,
	TProduct,
} from '@/modules/application';
import { KEYBOARD_CODES } from '@/constants';
import { getProductFromModalData, getProductItemCountInCart } from './product-modal-selectors';
import { PRODUCT_MODAL_NAME } from './product-modal-constants';
import { IProductPageControllerComponentProps } from './product-modal-types';
import { ProductModal } from './product-modal';

export class ProductModalControllerComponent extends React.PureComponent<IProductPageControllerComponentProps> {
	componentDidMount(): void {
		document.addEventListener('keydown', this.keydownHandler);
	}

	componentWillUnmount(): void {
		document.removeEventListener('keydown', this.keydownHandler);
	}

	addToCart = (event: React.MouseEvent, productItem: TProduct): void => {
		event.stopPropagation();
		this.props.addCartItemToStorage(productItem);
	};

	keydownHandler = (event: KeyboardEvent) => {
		event.stopPropagation();
		if (event.keyCode === KEYBOARD_CODES.ESC) {
			this.props.closeModal();
		}
	};

	render() {
		const {
			productItem,
			productItemCartCount,
			closeModal,
			isOpen,
		} = this.props;
		return (
			<ProductModal
				productItem={productItem}
				productItemCartCount={productItemCartCount}
				addToCart={this.addToCart}
				closeModal={closeModal}
				isOpen={isOpen}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	isOpen: getModalVisibility(PRODUCT_MODAL_NAME),
	productItem: getProductFromModalData,
	productItemCartCount: getProductItemCountInCart,
});

const mapDispatchToProps = {
	addCartItemToStorage,
	closeModal: () => closeModalAction({ name: PRODUCT_MODAL_NAME }),
};

export const ProductModalController = compose(
	withModals,
	connect(mapStateToProps, mapDispatchToProps),
)(ProductModalControllerComponent);
