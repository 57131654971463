import {
	CART_PERSONAL_DATA_FORM_FIELDS,
	DELIVERY_METHODS,
	DELIVERY_TIME_METHODS,
} from '../../cart-constants';

export const personalDataStepValidator = (values) => {
	const errors = {};
	const isDelivery = values[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_METHOD] === DELIVERY_METHODS.DELIVERY;
	const isDeliveryAtTime = values[
		CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_TIME_METHOD
	] === DELIVERY_TIME_METHODS.AT_TIME;
	const isTakeAwayAtTime = values[
		CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_TIME_METHOD
	] === DELIVERY_TIME_METHODS.AT_TIME;

	if (!values[CART_PERSONAL_DATA_FORM_FIELDS.NAME] || values[CART_PERSONAL_DATA_FORM_FIELDS.NAME].length < 2) {
		errors[CART_PERSONAL_DATA_FORM_FIELDS.NAME] = 'Поля обязательно для заполнения';
	}
	if (
		!values[CART_PERSONAL_DATA_FORM_FIELDS.PHONE]
		|| values[CART_PERSONAL_DATA_FORM_FIELDS.PHONE].trim().length < 10
	) {
		errors[CART_PERSONAL_DATA_FORM_FIELDS.PHONE] = 'Поля обязательно для заполнения';
	}
	if (isDelivery && !values[CART_PERSONAL_DATA_FORM_FIELDS.STREET]) {
		errors[CART_PERSONAL_DATA_FORM_FIELDS.STREET] = 'Поля обязательно для заполнения';
	}
	if (isDelivery && !values[CART_PERSONAL_DATA_FORM_FIELDS.HOME]) {
		errors[CART_PERSONAL_DATA_FORM_FIELDS.HOME] = 'Поля обязательно для заполнения';
	}
	if (values[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY] && !values[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR]) {
		errors[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR] = 'Выберите часы';
	}
	if (isDeliveryAtTime) {
		if (!values[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY]) {
			errors[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY] = 'Выберите день';
		}
		if (
			values[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_DAY]
			&& !values[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR]
		) {
			errors[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR] = 'Выберите часы';
		}
		if (
			values[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_HOUR]
			&& !values[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_MINUTE]
		) {
			errors[CART_PERSONAL_DATA_FORM_FIELDS.DELIVERY_MINUTE] = 'Выберите минуты';
		}
	}
	if (isTakeAwayAtTime) {
		if (!values[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR]) {
			errors[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR] = 'Выберите часы';
		}
		if (
			values[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_HOUR]
			&& !values[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_MINUTE]
		) {
			errors[CART_PERSONAL_DATA_FORM_FIELDS.TAKEAWAY_MINUTE] = 'Выберите минуты';
		}
	}

	return errors;
};

export const personalDataStepWarner = (values) => {
	const warnings = {};

	if (
		values[CART_PERSONAL_DATA_FORM_FIELDS.PHONE]
		&& values[CART_PERSONAL_DATA_FORM_FIELDS.PHONE]?.[4] !== '9'
		|| values[CART_PERSONAL_DATA_FORM_FIELDS.PHONE]?.trim().length < 18
	) {
		warnings[CART_PERSONAL_DATA_FORM_FIELDS.PHONE] = 'Пожалуйста, проверьте правильность введенного номера';
	}

	return warnings;
};