import * as React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { TProduct } from '@/modules/application';
import { Button, Column, Row } from '@/modules/aline';
import { Product } from '@/modules/product';
import { Heading } from '@/components/heading';
import { TableBody, TableHead } from '../../components/table';
import { ICartDataStepProps } from './cart-data-step-types';
import styles from './cart-data.module.scss';

const productColumnWidth = {
	default: 16,
	l: 17,
	s: 25,
	xs: 100,
};

export const CartDataStep = ({
	headColumns,
	bodyColumns,
	rows,
	onRowDelete,
	personalStepPath,
	cartSummary,
	clearCartData,
	rememberProductProposals,
	showProductModal,
}: ICartDataStepProps) => (
	<div className={styles['cart']}>
		<div className={styles['table']}>
			<TableHead headColumns={headColumns} />
			<TableBody
				rows={rows}
				bodyColumns={bodyColumns}
				onRowDelete={onRowDelete}
				showProductModal={showProductModal}
			/>
		</div>

		<div className={styles['actions']}>
			<Button
				onClick={clearCartData}
				size="small"
				color="black"
				border={3}
				bold
			>
				Очистить
			</Button>
		</div>

		<div className={cn(
			styles['summary'],
			't-r',
		)}>
			Итого: <span className={styles['summary-number']}>{cartSummary} руб.</span>
		</div>

		<div className={cn(
			styles['actions'],
			't-r',
		)}>
			<Link to={personalStepPath}>
				<Button
					size="small"
					color="black"
					border={3}
					bold
				>
					Далее
				</Button>
			</Link>
		</div>
		{!!rememberProductProposals.length && (
			<div className={styles['remember-items']}>
				<Heading
					size={4}
					text="Не забудьте добавить"
					textCenter
					className="ptb-20"
				/>
				<Row className="ai-str">
					{rememberProductProposals.map((productItem: TProduct) => (
						<Column
							width={productColumnWidth}
							key={productItem.id}
						>
							<Product
								productItem={productItem}
								size="small"
							/>
						</Column>
					))}
				</Row>
			</div>
		)}
	</div>
);
