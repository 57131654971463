import * as React from 'react';
import { Field } from 'redux-form';
import { Search } from './search';
import { ISearchControllerProps } from './search-types';
import { SEARCH_NOTE } from './search-constants';
import styles from './search.module.scss';

export const SearchController = React.memo(({ fieldName }: ISearchControllerProps) => (
	<div className={styles['search']}>
		<Field
			name={fieldName}
			component={Search}
			type="text"
		/>
		<div className={styles['search-note']}>
			{SEARCH_NOTE}
		</div>
	</div>
));
