import * as React from 'react';
import cn from 'classnames';
import ProgressiveImage from 'react-progressive-image';
import { ProductCounter } from '@/modules/product-counter';
import { ProductLabels } from '@/components/product-labels';
import { CURRENCY, XS_PRODUCT_IMAGE_MIN_WIDTH, IMAGE_PRELOADER } from './product-constants';
import { IProductProps } from './product-types';
import styles from './product.module.scss';

export const Product = React.memo(({
	productItem,
	elementWidth,
	goToProduct,
	productRef,
	addToCart,
	productItemCartCount,
	size = 'default',
	showProductModal,
}: IProductProps) => (
	<div
		className={cn(
			styles['product'],
			{
				[styles[`product--${size}`]]: size,
			},
		)}
		ref={productRef}
	>
		<div className={styles['product-top']}>
			<ProgressiveImage
				src={productItem.image}
				placeholder={IMAGE_PRELOADER}
			>
				{(src, isLoading) => (
					<div
						onClick={showProductModal}
						className={cn(
							styles['product-image'],
							{
								[styles['product-image--loading']]: isLoading,
							},
						)}
						style={{
							backgroundImage: `url(${src})`,
							height: elementWidth || XS_PRODUCT_IMAGE_MIN_WIDTH,
						}}
					>
						<ProductLabels
							isNew={productItem.isNew}
							isPopular={productItem.isPopular}
							align="right"
						/>
					</div>
				)}
			</ProgressiveImage>
			<div className={styles['product-data']}>
				<div onClick={goToProduct}>
					<div className={styles['product-name']}>
						{productItem.name}
					</div>
					<div className={styles['product-ingredients']}>
						{productItem.ingredients}
						<React.Fragment>
							{(!!productItem.quantity && (
								<span className={styles['product-quantity']}>
									({productItem.quantity} шт.)
								</span>
							)) || (!!productItem.productPortionSize && (
								<span className={styles['product-quantity']}>
									({productItem.productPortionSize})
								</span>
							))}
						</React.Fragment>
					</div>
				</div>
			</div>
		</div>

		<div className={styles['product-bottom']}>
			<div className={styles['product-price']}>
				<span className={styles['number']}>
					{productItem.price}
				</span>
				<span className={styles['currency']}>
					{CURRENCY}
				</span>
			</div>
			<div className={styles['product-actions']}>
				{productItemCartCount ? (
					<ProductCounter
						id={productItem.id}
						value={productItemCartCount}
						width={size === 'small' ? 100 : 55}
					/>
				) : (
					<button
						className={styles['add-button']}
						onClick={(event: React.MouseEvent) => addToCart(event, productItem)}
					>
						Добавить
					</button>
				)}
			</div>
		</div>
	</div>
));
