import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { match as Match } from 'react-router-dom';
import { TProduct } from '@/modules/application';
import { getTitlesData } from './application-actions';
import { createGetTitle } from './application-selectors';

type TWithTitlesComponent = {
	title?: string,
	productItem: TProduct,
	getTitlesData: () => Promise<void>,
	match: Match,
}

export const withTitles = (WrappedComponent: React.ComponentType) => {
	class WithTitlesComponent extends React.PureComponent<TWithTitlesComponent> {
		displayName = `with ProductsDataComponent (${WrappedComponent.displayName || WrappedComponent.name})`;

		componentDidMount(): void {
			this.props.getTitlesData().then(() => {
				// NOTE Потенциально пропсы могут еще не прийти после подгрузки с сервера, и будет ошибка установки тайтла
				document.title = this.props.productItem ? `${this.props.productItem.name} - Окинава ` : this.props.title;
			});
		}

		render() {
			return (
				<WrappedComponent
					{...omit(this.props, 'getTitlesData')}
				/>
			);
		}
	}

	const mapStateToProps = (state, props) => {
		const getTitle = createGetTitle(props.match?.url);

		return createStructuredSelector({
			title: getTitle,
		});
	};

	const mapDispatchToProps = {
		getTitlesData,
	};

	return connect(mapStateToProps, mapDispatchToProps)(WithTitlesComponent);
};
