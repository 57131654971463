import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import { KEYBOARD_CODES } from '@/constants';
import { rootNode } from '@/nodes';
import { IModalProps } from './modal-types';
import styles from './modal.module.scss';

export class Modal extends React.PureComponent<IModalProps> {
	modalRef: React.RefObject<any> = React.createRef();

	componentDidMount(): void {
		window.addEventListener('popstate', this.handleClose);
	}

	componentDidUpdate(): void {
		if (this.props.isOpen) {
			document.documentElement.style.overflow = 'hidden';
		} else {
			document.documentElement.style.overflow = 'auto';
		}
	}

	componentWillUnmount(): void {
		window.removeEventListener('popstate', this.handleClose);
	}

	handleClose = () => {
		this.props.closeModal();
	};

	clickOver = (event: React.MouseEvent<any>) => {
		if (!this.modalRef.current.contains(event.target)) {
			this.handleClose();
		}
	};

	keydownHandler = (event: React.KeyboardEvent<any>) => {
		event.stopPropagation();
		if (event.keyCode === KEYBOARD_CODES.ESC) {
			this.handleClose();
		}
	};

	render() {
		const {
			isOpen,
			size,
			className,
		} = this.props;

		return ReactDOM.createPortal((
			<div
				className={cn(
					styles['wrapper'],
					{
						[styles['wrapper--active']]: isOpen,
					},
				)}
				onClick={this.clickOver}
			>
				<div className={styles['container']}>
					<div
						tabIndex={0}
						ref={this.modalRef}
						onKeyDown={this.keydownHandler}
						className={cn(
							styles['modal'],
							{
								[styles[`modal-${size}`]]: size,
							},
						)}
					>
						<div
							className={styles['close']}
							onClick={this.handleClose}
						>
							×
						</div>
						<div className={cn(
							styles['body'],
							className,
						)}>
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		), rootNode);
	}
}
