import {
	categoriesStub,
	productsStub,
	settingsStub,
} from '@/stubs';
import {
	SET_CATEGORIES,
	SET_PRODUCTS,
	SET_CATEGORIES_REFERENCE,
	SET_SETTINGS,
	SET_HEADER_REFERENCE,
	SET_TITLES,
} from '../application-actions';
import { TApplicationState } from '../application-types';

export const applicationState: TApplicationState = {
	categories: process.env.NODE_ENV === 'development' ? categoriesStub : [],
	products: process.env.NODE_ENV === 'development' ? productsStub : [],
	categoriesPositions: {},
	headerReference: {},
	settings: process.env.NODE_ENV === 'development' ? settingsStub : {},
	titles: [],
};

export const applicationReducer = (state = applicationState, { type, payload }) => {
	switch (type) {
	case SET_CATEGORIES:
		return {
			...state,
			categories: payload,
		};
	case SET_PRODUCTS:
		return {
			...state,
			products: payload,
		};
	case SET_CATEGORIES_REFERENCE:
		return {
			...state,
			categoriesPositions: {
				...state.categoriesPositions,
				[payload.categoryId]: payload.categoryRef,
			},
		};
	case SET_SETTINGS:
		return {
			...state,
			settings: payload,
		};
	case SET_HEADER_REFERENCE:
		return {
			...state,
			headerReference: payload,
		};
	case SET_TITLES:
		return {
			...state,
			titles: payload,
		};
	default:
		return state;
	}
};
