import * as React from 'react';
import cn from 'classnames';
import OwlCarousel from 'react-owl-carousel';
import { TCategory } from '@/modules/application';
import { BarItem } from './components/bar-item';
import { ICategoriesBarProps } from './categories-bar-types';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styles from './categories-bar.module.scss';

export const CategoriesBar = ({
	fixed,
	categories,
	onCategoryClick,
	categoriesBarRef,
	isCategoriesBarVisible,
}: ICategoriesBarProps) => (
	<div
		className={cn(
			styles['categories-bar'],
			{
				[styles['categories-bar--fixed']]: fixed,
				[styles['categories-bar--showed']]: isCategoriesBarVisible,
			},
		)}
		ref={categoriesBarRef}
	>
		<OwlCarousel
			className={cn(
				styles['categories-bar-owl-wrapper'],
				'hidden-xs-d',
			)}
			stageClass={styles['categories-bar-owl-stage']}
			navContainerClass={styles['categories-bar-owl-nav']}
			margin={15}
			dots={false}
			items={12}
			nav
			responsive={{
				0: {
					items: 4,
					margin: 10,
					nav: false,
				},
				767: {
					items: 8,
				},
				1279: {
					items: 12,
				},
				1579: {
					items: 17,
				},
			}}
		>
			{!!categories.length && categories.map(({ id, image, name }: TCategory) => (
				<BarItem
					key={id}
					id={id}
					image={image}
					name={name}
					onCategoryClick={onCategoryClick}
					fixed={fixed}
				/>
			))}
		</OwlCarousel>
		<div className={cn(
			styles['categories-bar--mobile'],
			'hidden-s-u',
		)}>
			{!!categories.length && categories.map(({ id, image, name }: TCategory) => (
				<BarItem
					key={id}
					id={id}
					image={image}
					name={name}
					onCategoryClick={onCategoryClick}
					fixed={fixed}
				/>
			))}
		</div>
	</div>
);