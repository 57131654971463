import axios from 'axios';
import { API } from '@/api';

export const SET_SALES = 'SET_SALES';
export const setSales = (payload) => ({
	type: SET_SALES,
	payload,
});

export const getSalesFromServer = () => (dispatch) => axios({
	method: 'get',
	url: API.URLS.SALES,
})
	.then((response) => dispatch(setSales(response.data)))
	.catch((error) => console.log('Ошибка получения акций', error));
