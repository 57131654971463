import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
	getProposals,
	addCartItemToStorage,
	withProductsData,
	withTitles,
	TProduct,
} from '@/modules/application';
import { IProductPageControllerComponent } from './product-page-types';
import { ProductPage } from './product-page';
import { getProduct, getProductItemCountInCart } from './product-page-selectors';

// TODO (perspective) Есть проблема с тайтлами на этой странице продукта, не подгружается нужный тайтл

export class ProductPageControllerComponent extends React.PureComponent<IProductPageControllerComponent> {
	addToCart = (event: React.MouseEvent, productItem: TProduct): void => {
		event.stopPropagation();
		this.props.addCartItemToStorage(productItem);
	};

	render() {
		const { productItem, productItemCartCount, proposals } = this.props;
		return (
			<ProductPage
				productItem={productItem}
				productItemCartCount={productItemCartCount}
				addToCart={this.addToCart}
				proposals={proposals}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	productItem: getProduct,
	productItemCartCount: getProductItemCountInCart,
	proposals: getProposals,
});

const mapDispatchToProps = {
	addCartItemToStorage,
};

export const ProductPageController = compose(
	withProductsData,
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	withTitles,
)(ProductPageControllerComponent);
