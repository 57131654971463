import * as React from 'react';
import { IYaMapProps } from './ya-map-types';
import styles from './ya-map.module.scss';

export class YaMap extends React.PureComponent<IYaMapProps> {
	componentDidMount(): void {
		this.yaMap();
	}

	yaMap() {
		const {
			latitude,
			longitude,
			mapBaloonContent,
		} = this.props;

		const init = () => {
			// @ts-ignore
			const myMap = new ymaps.Map('YMapsID', {
				center: [latitude, longitude],
				zoom: 16,
				controls: ['zoomControl'],
			});
			// @ts-ignore
			const myPlacemark = new ymaps.Placemark([latitude, longitude],
				{
					hintContent: 'okinava64.ru',
					balloonContent: mapBaloonContent,
				},
				{
					preset: 'islands#dot',
					iconColor: '#1e98ff',
				},
			);
			myMap.geoObjects.add(myPlacemark);
		};
		// @ts-ignore
		ymaps.ready(init);
	}

	render() {
		return (
			<div
				id="YMapsID"
				className={styles['YAMap']}
			/>
		);
	}
}
