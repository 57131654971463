import * as React from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
	getSettings,
	getIsOrderProcessing,
	withSettingsData,
	setClientDataToStorage,
	removeClientDataFromStorage,
	createOrder,
} from '@/modules/application';
import { withSimpleModal } from '@/modules/modals';
import { toHomePage } from '@/routes/routes-utils';
import {
	CART_PERSONAL_DATA_FORM_NAME,
	CART_PERSONAL_DATA_FORM_FIELDS,
} from '../../cart-constants';
import { CartPersonalStep } from './cart-personal-step';
import {
	getInitialValues,
	getClientDataFormValues,
	getDeliveryTimeOptions,
	getTakeAwayTimeOptions,
	getClientDataToSave,
	getOrderErrors,
	getOrderWarnings,
	getPreparedDataForServer,
	getPreparedCartItemsForCall2,
} from './cart-personal-step-selectors';
import {
	ORDER_DONE_TITLE_DEFAULT,
	ORDER_DONE_MESSAGE_DEFAULT,
	ORDER_DONE_BUTTON_TITLE_DEFAULT,
	ORDER_DONE_ERROR_TEXT_DEFAULT,
} from './cart-personal-step-constants';
import { personalDataStepValidator, personalDataStepWarner } from './cart-personal-step-validator';
import { ICartPersonalStepControllerComponentProps } from './cart-personal-step-types';

export class CartPersonalStepControllerComponent extends React.PureComponent<
	ICartPersonalStepControllerComponentProps
> {
	onDataSend = async () => {
		// TODO perspective - просто ЖОПА с этими функциями которые стучаться на бек и
		// TODO правильным логированием, все должно быть в одном экшене и возвращаться логичный респонс
		// TODO тут сейчас реализовано плохо, из-за того что тороплюсь и клиент не платит.
		// TODO Когда вернусь сюда нужно все отрефачить, и посидеть разобраться
		// TODO рефачить нужно как тут так и на беке
		// TODO perspective данную функцию нужно перенести в экшены и отрефачить
		const {
			clientDataToSave,
			clientDataFormValues,
			orderErrors,
			preparedDataForServer,
			preparedDataForCall2,
			settings,
		} = this.props;

		// TODO неправильно, не должно быть такого поведения кнопки, onClick не должен срабатывать если на кнопке disabled
		// TODO нужно добавить такое дефолтное поведение в aline
		if (!isEmpty(orderErrors)) {
			return;
		}

		const response = await this.props.createOrder(preparedDataForServer, preparedDataForCall2);
		// TODO perspective избавиться от четырехэтажных ифов
		// TODO perspective приходящее сообщение должно быть от сервера с
		// TODO номером заказа, нужно этот момент рефачить и тут и на беке
		if (response?.success) {
			if (response?.orderNumber) {
				await this.props.showSimpleModal({
					header: settings?.orderDoneTitle || ORDER_DONE_TITLE_DEFAULT,
					message: settings?.orderDoneMessage
						? `${settings?.orderDoneMessage} Номер заказа ${response.orderNumber}`
						: ORDER_DONE_MESSAGE_DEFAULT,
					yesButton: {
						title: settings?.orderDoneButtonTitle || ORDER_DONE_BUTTON_TITLE_DEFAULT,
					},
				});
			} else {
				await this.props.showSimpleModal({
					header: settings?.orderDoneTitle || ORDER_DONE_TITLE_DEFAULT,
					message: settings?.orderDoneMessage || ORDER_DONE_MESSAGE_DEFAULT,
					yesButton: {
						title: settings?.orderDoneButtonTitle || ORDER_DONE_BUTTON_TITLE_DEFAULT,
					},
				});
			}
			if (clientDataFormValues[CART_PERSONAL_DATA_FORM_FIELDS.DATA_SAVE]) {
				this.props.setClientDataToStorage(clientDataToSave);
			}
			if (!clientDataFormValues[CART_PERSONAL_DATA_FORM_FIELDS.DATA_SAVE]) {
				this.props.removeClientDataFromStorage();
			}
			this.props.reset();
			this.props.destroy();
			this.props.clearCartData();
			toHomePage();
		}

		if (!response?.success) {
			await this.props.showSimpleModal({
				header: 'Внимание!',
				message: response?.messages?.length ? response?.messages[0] : ORDER_DONE_ERROR_TEXT_DEFAULT,
				yesButton: {
					title: 'Ок',
				},
			});
		}
	};

	render() {
		const {
			settings: { address },
			clientDataFormValues,
			deliveryTimeOptions,
			takeAwayTimeOptions,
			invalid,
			orderErrors,
			orderWarnings,
			isOrderProcessing,
		} = this.props;
		return (
			<CartPersonalStep
				address={address}
				formValues={clientDataFormValues}
				onDataSend={this.onDataSend}
				deliveryTimeOptions={deliveryTimeOptions}
				takeAwayTimeOptions={takeAwayTimeOptions}
				invalid={invalid}
				orderErrors={orderErrors}
				orderWarnings={orderWarnings}
				isOrderProcessing={isOrderProcessing}
			/>
		);
	}
}

// TODO (тут у меня ошибка ТС, если ее нет удалить туду)
const mapStateToProps = createStructuredSelector({
	settings: getSettings,
	initialValues: getInitialValues,
	clientDataFormValues: getClientDataFormValues,
	deliveryTimeOptions: getDeliveryTimeOptions,
	takeAwayTimeOptions: getTakeAwayTimeOptions,
	clientDataToSave: getClientDataToSave,
	orderErrors: getOrderErrors,
	orderWarnings: getOrderWarnings,
	preparedDataForServer: getPreparedDataForServer,
	preparedDataForCall2: getPreparedCartItemsForCall2,
	isOrderProcessing: getIsOrderProcessing,
});

const mapDispatchToProps = {
	setClientDataToStorage,
	removeClientDataFromStorage,
	createOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const CartPersonalStepController = compose(
	withSimpleModal,
	withSettingsData,
	connector,
	reduxForm({
		form: CART_PERSONAL_DATA_FORM_NAME,
		// enableReinitialize: true, // При включенном, постояноо будет менять поля формы на новые
		validate: personalDataStepValidator,
		warn: personalDataStepWarner,
		destroyOnUnmount: false, // Уничтожать ли при анмоутинге (нет, так как при роутинге, пропадают введеные данные)
	}),
)(CartPersonalStepControllerComponent);