import * as React from 'react';
import cn from 'classnames';
import { ReactComponent as PlusIcon } from '@/assets/svg/plus.svg';
import { ReactComponent as MinusIcon } from '@/assets/svg/minus.svg';
import { IProductCounterProps } from './product-counter-types';
import styles from './product-counter.module.scss';

export const ProductCounter = ({
	onPlus,
	onMinus,
	changeHandler,
	keydownHandler,
	id,
	value,
	width = 100,
	className,
}: IProductCounterProps) => (
	<div
		className={cn(
			styles['counter'],
			className,
		)}
		style={{ width: `${width}%` }}
	>
		<button
			className={styles['minus']}
			onClick={(event: React.MouseEvent) => onMinus(event, id)}
		>
			<MinusIcon />
		</button>
		<input
			type="text"
			className={styles['counter-value']}
			value={value}
			onChange={(event: React.ChangeEvent<any>) => changeHandler(event, id)}
			onKeyDown={(event: React.KeyboardEvent) => keydownHandler(event, id)}
		/>
		<button
			className={styles['plus']}
			onClick={(event: React.MouseEvent) => onPlus(event, id)}
		>
			<PlusIcon />
		</button>
	</div>
);
