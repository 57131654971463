import * as React from 'react';
import { connect } from 'react-redux';
import { showModalAction, closeModalAction } from './modals-actions';
import { IWithModalsProps, TModalsPayload } from './modals-types';

export const withModals = (WrappedComponent: React.ComponentType<any>) => {
	class WithModalsComponent extends React.PureComponent<IWithModalsProps> {
		displayName = `with ModalsComponent (${WrappedComponent.displayName || WrappedComponent.name})`;

		closeModal = (payload: TModalsPayload) => this.props.closeModalAction(payload);

		showModal = (payload: TModalsPayload) => this.props.showModalAction(payload);

		render() {
			return (
				<WrappedComponent
					{...this.props}
					closeModal={this.closeModal}
					showModal={this.showModal}
				/>
			);
		}
	}

	const mapDispatchToProps = {
		showModalAction,
		closeModalAction,
	};

	return connect(null, mapDispatchToProps)(WithModalsComponent);
};
