import * as React from 'react';
import cn from 'classnames';
import { ReactComponent as CartSvg } from '@/assets/svg/cart.svg';
import { ICartPresenterProps } from './cart-presenter-types';
import styles from './cart-presenter.module.scss';

export const CartPresenter = React.memo(({ cartSummary, onClick, isActive, text, isSidebar }: ICartPresenterProps) => (
	<div
		className={cn(
			styles['cart'],
			{
				[styles['cart--active']]: isActive,
				[styles['cart--sidebar']]: isSidebar,
			},
		)}
		onClick={onClick}
		title="Перейти в корзину"
	>
		<CartSvg />
		{!!cartSummary && (
			<span className={styles['cart-count']}>
				{cartSummary} руб.
			</span>
		)}
		{text && (
			<span className={styles['cart-text']}>
				{text}
			</span>
		)}
	</div>
));
