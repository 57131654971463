import * as React from 'react';
import cn from 'classnames';
import { TProduct } from '@/modules/application';
import { Row, Column } from '@/modules/aline';
import { Product } from '@/modules/product';
import { Heading } from '@/components/heading';
import { ICategoryProps } from './category-types';
import styles from './category.module.scss';

// TODO (perspective) много повторений (оставляем так, но потом лучше отрефачить)
const productColumnWidth = {
	default: 20,
	l: 25,
	s: 33,
	xs: 100,
};

export const Category = ({
	categoryItem,
	categoryRef,
}: ICategoryProps) => {
	const { products } = categoryItem;
	return (
		<div
			className={styles['category']}
			ref={categoryRef}
		>
			<Heading
				size={3}
				text={categoryItem.name}
				className="tt-uc ptb-20"
				textCenter
			/>
			<Row className={cn(
				styles['product-row'],
				'ai-str',
			)}>
				{!!products.length && products.map((productItem: TProduct) => (
					<Column
						width={productColumnWidth}
						key={productItem.id}
					>
						<Product
							productItem={productItem}
						/>
					</Column>
				))}
			</Row>
		</div>
	);
};
