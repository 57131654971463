import * as React from 'react';
import cn from 'classnames';
import { toHomePage } from '@/routes/routes-utils';
import { ILogoProps } from './logo-types';
import styles from './logo.module.scss';

export const Logo = React.memo(({ image, title, left, closeSidebar }: ILogoProps) => {
	const goToHome = () => {
		// Компонент не должен иметь такой функции, он должен быть тупым. Но этот - исключение.
		if (closeSidebar) {
			closeSidebar();
		}
		if (location.pathname === '/') {
			return window.scrollTo({ top: 0 });
		}
		return toHomePage();
	};
	return (
		<div
			className={cn(
				styles['logo'],
				{
					[styles['logo--left']]: left,
				},
			)}
			style={{ backgroundImage: `url(${image})` }}
			title={title}
			onClick={goToHome}
		/>
	);
});
