import axios from 'axios';
import { Dispatch } from 'redux';
import { API } from '@/api';

export const SET_CAROUSEL_ITEMS = 'SET_CAROUSEL_ITEMS';
export const setCarouselItems = (payload) => ({
	type: SET_CAROUSEL_ITEMS,
	payload,
});

export const getCarouselItems = () => (dispatch: Dispatch) => {
	return axios({
		method: 'get',
		url: API.URLS.CAROUSEL,
	})
		.then((response) => dispatch(setCarouselItems(response.data)))
		.catch((error) => console.log('Ошибка получения изображений слайдера', error));
};