import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import {
	getProducts,
	TClientDataFormValues,
	TProduct,
} from '@/modules/application';
import {
	EMPTY_ARRAY,
	SEARCH_FIELD_NAMES,
	SEARCH_PAGE_FORM_NAME,
} from './search-page-constants';

export const getProductsWithSearch = createSelector(
	getProducts,
	getFormValues(SEARCH_PAGE_FORM_NAME),
	(
		products: Array<TProduct>,
		formValues: TClientDataFormValues,
	): Array<TProduct> | void => {
		const searchValue = (formValues?.[SEARCH_FIELD_NAMES.SEARCH] || '').trim().toLowerCase();
		return searchValue
			? products?.filter(({ name, ingredients, categoryName, id, isCategoryActive }: TProduct) => (
				isCategoryActive && JSON.stringify(Object.values({
					name,
					ingredients,
					categoryName,
					id,
				})).toLowerCase().includes(searchValue)))
			: EMPTY_ARRAY;
	},
);
