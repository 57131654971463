import * as React from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_CODES } from '@/constants';
import {
	changeCartItemCount,
	CART_ITEM_COUNT_OPERATIONS,
} from '@/modules/application';
import { ProductCounter } from './product-counter';
import { IProductCounterControllerComponentProps } from './product-counter-types';

export class ProductCounterControllerComponent extends React.PureComponent<IProductCounterControllerComponentProps> {
	onRowCounterMinus = (event: React.MouseEvent<any>, id: string | number) => {
		event.stopPropagation();
		this.props.changeCartItemCount(id, CART_ITEM_COUNT_OPERATIONS.MINUS);
	};

	onRowCounterPlus = (event: React.MouseEvent<any>, id: string | number) => {
		event.stopPropagation();
		this.props.changeCartItemCount(id, CART_ITEM_COUNT_OPERATIONS.PLUS);
	};

	rowCounterChangeHandler = (event: React.ChangeEvent<any>, id: string | number) => {
		event.stopPropagation();
		this.props.changeCartItemCount(id, CART_ITEM_COUNT_OPERATIONS.UPDATE, event.target.value);
	};

	rowCounterKeydownHandler = (event: React.KeyboardEvent<any>, id: string | number) => {
		event.stopPropagation();
		if (event.keyCode === KEYBOARD_CODES.ARROW_DOWN) {
			this.props.changeCartItemCount(id, CART_ITEM_COUNT_OPERATIONS.MINUS);
		}
		if (event.keyCode === KEYBOARD_CODES.ARROW_UP) {
			this.props.changeCartItemCount(id, CART_ITEM_COUNT_OPERATIONS.PLUS);
		}
	};

	render() {
		const { value, width, id, className } = this.props;
		return (
			<ProductCounter
				onPlus={this.onRowCounterPlus}
				onMinus={this.onRowCounterMinus}
				changeHandler={this.rowCounterChangeHandler}
				keydownHandler={this.rowCounterKeydownHandler}
				value={value}
				width={width}
				id={id}
				className={className}
			/>
		);
	}
}

const mapDispatchToProps = {
	changeCartItemCount,
};

export const ProductCounterController = connect(null, mapDispatchToProps)(ProductCounterControllerComponent);
