import * as React from 'react';
import cn from 'classnames';
import { ISelectInputProps, TOption } from './select-input-types';
import styles from './select-input.module.scss';

export const SelectInput = ({
	label,
	input,
	options,
	disabledValue,
	meta: { touched, error, warning },
}: ISelectInputProps) => (
	<React.Fragment>
		{label && (
			<label className={styles['label']}>
				{label}
			</label>
		)}
		<div className={cn(
			styles['wrapper'],
			{
				[styles['wrapper--error']]: error,
				[styles['wrapper--warning']]: warning,
				// [styles['wrapper--welldone']]: !error && !warning,
			},
		)}>
			<select
				className={styles['select']}
				{...input}
				name={input.name}
				onChange={input.onChange}
				value={input.value}
			>
				{disabledValue && (
					<option
						disabled
						selected
						value={''}
					>
						{disabledValue}
					</option>
				)}
				{options.length > 0 && options.map(({ value, textValue }: TOption) => (
					<option
						key={String(value)}
						value={value}
					>
						{textValue}
					</option>
				))}
			</select>
		</div>
		{error && touched && (
			<div className={styles['error']}>
				{error}
			</div>
		)}
		{!error && warning && touched && (
			<div className={styles['error']}>
				{warning}
			</div>
		)}
	</React.Fragment>
);
