import * as React from 'react';
import { SimpleModal, IShowSimpleModalArgs } from './simple-modal';

export const withSimpleModal = (WrappedComponent: React.ComponentType<any>) => (
	class WithSimpleModalComponent extends React.PureComponent<any> {
		simpleModalRef: React.RefObject<any> = React.createRef();

		handleShow = (simpleModalParams: IShowSimpleModalArgs): void => {
			const { current } = this.simpleModalRef;
			if (current !== null) {
				return current.show(simpleModalParams);
			}
		};

		render() {
			return (
				<React.Fragment>
					<SimpleModal
						ref={this.simpleModalRef}
					/>
					<WrappedComponent
						showSimpleModal={this.handleShow}
						{...this.props}
					/>
				</React.Fragment>
			);
		}
	}
);
