import * as React from 'react';
import { SimpleModal } from './simple-modal';
import {
	ISimpleModalControllerState,
	IShowSimpleModalArgs,
	IPromiseInfo,
} from './simple-modal-types';

export class SimpleModalController extends React.PureComponent<{}, ISimpleModalControllerState> {
	displayName = 'SimpleModalController';

	state: ISimpleModalControllerState = {
		header: '',
		isOpen: false,
		message: '',
		noButton: {},
		yesButton: {},
	};

	promiseInfo: IPromiseInfo = {};

	handleOk = () => {
		this.hide();
		this.promiseInfo.resolve(true);
	};

	handleCancel = () => {
		this.hide();
		this.promiseInfo.reject(false);
	};

	show = (simpleModalParams: IShowSimpleModalArgs): Promise<boolean> => {
		this.setState({
			...simpleModalParams,
			isOpen: true,
		});

		return new Promise((resolve: (result: any) => void, reject) => {
			this.promiseInfo = {
				reject,
				resolve,
			};
		});
	};

	hide = () => {
		this.setState({
			isOpen: false,
			noButton: {},
			yesButton: {},
		});
	};

	render() {
		return (
			<SimpleModal
				handleOk={this.handleOk}
				handleCancel={this.handleCancel}
				hide={this.hide}
				{...this.state}
			/>
		);
	}
}
