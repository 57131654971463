import { TModalsPayload } from './modals-types';

export const SHOW_MODAL = 'SHOW_MODAL';
export const showModalAction = (payload: TModalsPayload) => ({
	type: SHOW_MODAL,
	payload,
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModalAction = (payload: TModalsPayload) => ({
	type: CLOSE_MODAL,
	payload,
});
