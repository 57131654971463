import { createSelector } from 'reselect';
import { STORE_KEYS } from '@/store/store-constants';
import { TApplicationState } from '@/modules/application';
import { TSidebarState } from './sidebar-types';

const getSidebarState = (state: TApplicationState) => state[STORE_KEYS.SIDEBAR];

export const getSidebarVisibility = createSelector(
	getSidebarState,
	(sidebarState: TSidebarState) => sidebarState.visibility,
);
