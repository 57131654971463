import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { withProductsData, withTitles, getHeaderReference } from '@/modules/application';
import { getProductsWithSearch } from './search-page-selectors';
import { SearchPage } from './search-page';
import { SEARCH_PAGE_FORM_NAME } from './search-page-constants';
import { ISearchPageControllerComponentProps } from './search-page-types';

export class SearchPageControllerComponent extends React.PureComponent<ISearchPageControllerComponentProps> {
	render() {
		const { products, headerReference } = this.props;
		return (
			<SearchPage
				products={products}
				headerReference={headerReference}
			/>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	products: getProductsWithSearch,
	headerReference: getHeaderReference,
});

export const SearchPageController = compose(
	withTitles,
	withRouter,
	withProductsData,
	connect(
		mapStateToProps,
	),
	reduxForm({
		form: SEARCH_PAGE_FORM_NAME,
	}),
)(SearchPageControllerComponent);
