import * as React from 'react';
import { compose } from 'redux';
import { withProductsData, withTitles } from '@/modules/application';
import { CartPage } from './cart-page';
import { TCartPageControllerComponentProps } from './cart-page-types';

export class CartPageControllerComponent extends React.PureComponent<TCartPageControllerComponentProps> {
	render() {
		return (
			<CartPage />
		);
	}
}

export const CartPageController = compose(
	withTitles,
	withProductsData,
)(CartPageControllerComponent);
