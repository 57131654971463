import { omit } from 'lodash';
import { SHOW_MODAL, CLOSE_MODAL } from './modals-actions';
import { IModalsState } from './modals-types';

export const modalsState: IModalsState = {};

export const modalsReducer = (state: IModalsState = modalsState, { type, payload }) => {
	switch (type) {
	case SHOW_MODAL:
		return {
			...state,
			[payload.name]: {
				isOpen: true,
				data: payload.data,
			},
		};
	case CLOSE_MODAL:
		return omit(state, payload?.name) || modalsState;
	default:
		return state;
	}
};
