import * as React from 'react';
import { history } from '@/history';
import { CartPresenter } from './cart-presenter';
import { ICartPresenterControllerProps } from './cart-presenter-types';

// TODO (perspective) придется переделать в модуль (но возможно и нет) (оставляю на будущее)
export class CartPresenterControllerComponent extends React.PureComponent<ICartPresenterControllerProps> {
	onClickHandler = () => {
		const { redirectTo, closeSidebar } = this.props;
		if (closeSidebar) {
			closeSidebar();
		}
		if (redirectTo) {
			history.push(redirectTo);
		}
		return;
	};

	// TODO (perspective)
	// @ts-ignore
	get isMatchedUrl() {
		return location.pathname.includes('cart');
	}

	render() {
		const { cartSummary, className, text, closeSidebar } = this.props;
		return (
			<CartPresenter
				onClick={this.onClickHandler}
				isActive={this.isMatchedUrl}
				className={className}
				cartSummary={cartSummary}
				text={text}
				isSidebar={!!closeSidebar}
			/>
		);
	}
}

export const CartPresenterController = CartPresenterControllerComponent;
