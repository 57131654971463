import * as React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Container, Row, Column } from '@/modules/aline';
import { Navigation } from '@/modules/navigation';
import { Socials } from '@/modules/socials';
import { PATHS } from '@/routes/routes-constants';
import { IFooterProps } from './footer-types';
import styles from './footer.module.scss';

export const Footer = React.memo(({ bgImage, phone1, phone2, footerText }: IFooterProps) => (
	<footer
		className={styles['footer']}
		style={{ backgroundImage: `url(${bgImage})` }}
	>
		<div className={styles['footer-wrapper']}>
			<div className={cn(
				styles['footer-top'],
				'hidden-xs-d',
			)}>
				<Container>
					<Navigation isFooter />
				</Container>
			</div>
			<div className={styles['footer-bottom']}>
				<Container>
					<div className={styles['footer-row']}>
						<span className={styles['footer-row-left-text']}>
							Телефон доставки:
						</span>
						<a
							className={styles['phone']}
							href={`tel:${phone1}`}
						>
							{phone1}
						</a>
						<a
							className={styles['phone']}
							href={`tel:${phone2}`}
						>
							{phone2}
						</a>
					</div>
					<div className={cn(
						styles['footer-row'],
						styles['policy-link'],
					)}>
						<Link
							to={PATHS.POLICY_PAGE}
							className={styles['link']}
							title="Политика конфидециальности"
						>
							Политика конфидециальности
						</Link>
					</div>
					<div className={cn(
						styles['footer-row'],
					)}>
						<Row
							className="fd-rr fd-xs-r"
							alignCenter
						>
							<Column width={{ default: 40, xs: 100 }}>
								<Socials />
							</Column>
							<Column width={{ default: 60, xs: 100 }}>
								<div className={styles['company']}>
									{footerText}
								</div>
							</Column>
						</Row>
					</div>
				</Container>
			</div>
		</div>
		<div className={styles['overlay']} />
	</footer>
));