import * as React from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import { getProductsData, getCategoriesData } from './application-actions';

type TWithProductsDataComponent = {
	getProductsData: () => void,
	getCategoriesData: () => void,
}

export const withProductsData = (WrappedComponent: React.ComponentType) => {
	class WithProductsDataComponent extends React.PureComponent<TWithProductsDataComponent> {
		displayName = `with ProductsDataComponent (${WrappedComponent.displayName || WrappedComponent.name})`;

		componentDidMount(): void {
			this.props.getProductsData();
			this.props.getCategoriesData();
		}

		render() {
			return (
				<WrappedComponent
					{...omit(this.props, 'getProductsData', 'getCategoriesData')}
				/>
			);
		}
	}

	const mapDispatchToProps = {
		getProductsData,
		getCategoriesData,
	};

	return connect(null, mapDispatchToProps)(WithProductsDataComponent);
};
