import * as React from 'react';
import cn from 'classnames';
import { Button, Column, Container, Row } from '@/modules/aline';
import {
	TDeliveryCondition,
	TDeliveryPaymentMethod,
	TDeliveryOrderType,
} from '@/modules/application';
import { toMenuPage } from '@/routes/routes-utils';
import { Header } from '@/modules/header';
import { Footer } from '@/modules/footer';
import { Heading } from '@/components/heading';
import { PageWrapper } from '@/components/page-wrapper';
import { YaMapCustom } from '@/components/ya-map';
import { IDeliveryPageProps } from './delivery-page-types';
import styles from './delivery-page.module.scss';

export const DeliveryPage = React.memo(({
	conditions,
	phone1,
	phone2,
	contactBlockText,
	contactBlockImage,
	paymentMethods,
	orderTypes,
	deliveryZonesIFrame,
}: IDeliveryPageProps) => (
	<React.Fragment>
		<PageWrapper>
			<Header />
			<div className={styles['contact-block']}>
				<Container>
					<Row alignCenter>
						<Column width={{ default: 50, xs: 100 }}>
							<div className={styles['contact-block-left']}>
								<div
									className={styles['contact-block-image']}
									style={{ backgroundImage: `url(${contactBlockImage})` }}
								/>
							</div>
						</Column>
						<Column width={{ default: 50, xs: 100 }}>
							<div className="contact-block-right">
								{phone1 && (
									<a href={`tel:${phone1}`} className={styles['phone']}>
										{phone1}
									</a>
								)}
								{phone2 && (
									<a href={`tel:${phone2}`} className={styles['phone']}>
										{phone2}
									</a>
								)}
								{contactBlockText && (
									<div className={styles['contact-block-text']}>
										{contactBlockText}
									</div>
								)}
								<div className={styles['contact-block-button']}>
									<Button
										onClick={toMenuPage}
										size="small"
										color="black"
										border={3}
										bold
									>
										Перейти в меню
									</Button>
								</div>
							</div>
						</Column>
					</Row>
				</Container>
			</div>

			<Container>
				<div className={styles['conditions']}>
					<Heading
						size={3}
						text="Условия доставки"
						textCenter
						className="ptb-30 mb-20"
					/>
					<Row>
						{!!conditions?.length && conditions.map((condition: TDeliveryCondition) => (
							<Column
								width={{ default: 33, xs: 100 }}
								key={condition.id}
							>
								<div className={styles['condition']}>
									{condition.number && (
										<div className={cn(
											styles['condition-row'],
											styles['condition-row--number'],
										)}>
											<span className={styles['number']}>
												{condition.number}
											</span>
											<span className={styles['number-description']}>
												{condition.numberDescription}
											</span>
										</div>
									)}
									{condition.accent && (
										<div className={cn(
											styles['condition-row'],
											styles['condition-row--accent'],
										)}>
											{condition.accent}
										</div>
									)}
									{condition.description && (
										<div className={cn(
											styles['condition-row'],
											styles['condition-row--description'],
										)}>
											{condition.description}
										</div>
									)}
								</div>
							</Column>
						))}
					</Row>
				</div>

				<div className={styles['conditions']}>
					<Heading
						size={3}
						text="Типы заказов"
						textCenter
						className="ptb-30 mb-20"
					/>
					<Row>
						{!!orderTypes?.length && orderTypes.map((orderType: TDeliveryOrderType) => (
							<Column
								width={{ default: 33, xs: 100 }}
								key={orderType.id}
							>
								<div className={styles['condition']}>
									{orderType.icon && (
										<div
											className={cn(
												styles['condition-row'],
												styles['condition-row--icon'],
											)}
											style={{ backgroundImage: `url(${orderType.icon})` }}
										/>
									)}
									{orderType.accent && (
										<div className={cn(
											styles['condition-row'],
											styles['condition-row--accent'],
										)}>
											{orderType.accent}
										</div>
									)}
									{orderType.description && (
										<div className={cn(
											styles['condition-row'],
											styles['condition-row--description'],
										)}>
											{orderType.description}
										</div>
									)}
								</div>
							</Column>
						))}
					</Row>
				</div>

				<div className={styles['conditions']}>
					<Heading
						size={3}
						text="Варианты оплаты"
						textCenter
						className="ptb-30 mb-20"
					/>
					<Row>
						{!!paymentMethods?.length && paymentMethods.map((paymentMethod: TDeliveryPaymentMethod) => (
							<Column
								width={{ default: 33, xs: 100 }}
								key={paymentMethod.id}
							>
								<div className={styles['condition']}>
									{paymentMethod.icon && (
										<div
											className={cn(
												styles['condition-row'],
												styles['condition-row--icon'],
											)}
											style={{ backgroundImage: `url(${paymentMethod.icon})` }}
										/>
									)}
									{paymentMethod.accent && (
										<div className={cn(
											styles['condition-row'],
											styles['condition-row--accent'],
										)}>
											{paymentMethod.accent}
										</div>
									)}
									{paymentMethod.description && (
										<div className={cn(
											styles['condition-row'],
											styles['condition-row--description'],
										)}>
											{paymentMethod.description}
										</div>
									)}
								</div>
							</Column>
						))}
					</Row>
				</div>

			</Container>
		</PageWrapper>
		{deliveryZonesIFrame && (
			<React.Fragment>
				<Heading
					size={3}
					text="Зона доставки"
					textCenter
					className="pt-30"
				/>
				<YaMapCustom iframe={deliveryZonesIFrame} />
			</React.Fragment>
		)}
		<Footer />
	</React.Fragment>
));
