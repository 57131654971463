export const API_URL = '/api';

export const AVAILABLE_API_URLS = {
	ORDER_CALL1: 'order-call1',
	ORDER_CALL2: 'order-call2',
	CAROUSEL: 'carousel',
	CATEGORIES: 'categories',
	POLICY: 'policy',
	PRODUCTS: 'products',
	SALES: 'sales',
	SETTINGS: 'settings',
	TITLES: 'titles',
};

export const API = {
	URLS: {
		ORDER_CALL1: `${API_URL}/${AVAILABLE_API_URLS.ORDER_CALL1}`,
		ORDER_CALL2: `${API_URL}/${AVAILABLE_API_URLS.ORDER_CALL2}`,
		CAROUSEL: `${API_URL}/${AVAILABLE_API_URLS.CAROUSEL}`,
		CATEGORIES: `${API_URL}/${AVAILABLE_API_URLS.CATEGORIES}`,
		POLICY: `${API_URL}/${AVAILABLE_API_URLS.POLICY}`,
		PRODUCTS: `${API_URL}/${AVAILABLE_API_URLS.PRODUCTS}`,
		SALES: `${API_URL}/${AVAILABLE_API_URLS.SALES}`,
		SETTINGS: `${API_URL}/${AVAILABLE_API_URLS.SETTINGS}`,
		TITLES: `${API_URL}/${AVAILABLE_API_URLS.TITLES}`,
	},
};