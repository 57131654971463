import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './app';
import { rootNode } from './nodes';
import 'aline.css/dist/aline.css';
import './styles/core.scss';
import './styles/custom.scss';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<App />,
	rootNode,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
