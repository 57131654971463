import * as React from 'react';
import { isEmpty } from 'lodash';
import { Column, Row } from '@/modules/aline';
import { Product } from '@/modules/product';
import { TProduct } from '@/modules/application';
import { ISearchResultsProps } from './search-results-types';
import styles from './search-results.module.scss';

const productColumnWidth = {
	default: 20,
	l: 25,
	s: 33,
	xs: 100,
};

export const SearchResults = React.memo(({ products }: ISearchResultsProps) => (
	// TODO (perspective aline-ui) ai-str сделать как пропсу и др тоже
	<div className={styles.searchResults}>
		<Row className="ai-str">
			{!isEmpty(products) && products.map((productItem: TProduct) => (
				<Column
					width={productColumnWidth}
					key={productItem.id}
				>
					<Product
						productItem={productItem}
					/>
				</Column>
			))}
		</Row>
	</div>
));
