import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getSortedCategories, getCategoriesPositions, getHeaderReference } from '@/modules/application';
import { CategoriesBar } from './categories-bar';
import {
	SCROLL_TO_CORRECTION_HEIGHT,
	SCROLL_EVENT_CORRECTION_HEIGHT,
} from './categories-bar-constants';
import { ICategoriesBarControllerComponentProps } from './categories-bar-types';

class CategoriesBarControllerComponent extends React.PureComponent<ICategoriesBarControllerComponentProps> {
	categoriesStaticBarRef: React.RefObject<any> = React.createRef();
	categoriesDynamicBarRef: React.RefObject<any> = React.createRef();

	state = {
		isCategoriesBarVisible: false,
	};

	componentDidMount(): void {
		document.addEventListener('scroll', this.checkBarPosition);
	}

	componentWillUnmount(): void {
		document.removeEventListener('scroll', this.checkBarPosition);
	}

	checkBarPosition = () => {
		const isScrollMoreThanBarPosition = window.scrollY > (
			this.categoriesStaticBarRef.current.offsetTop + SCROLL_EVENT_CORRECTION_HEIGHT
		);
		this.setState({
			isCategoriesBarVisible: isScrollMoreThanBarPosition,
		});
	};

	onCategoryClick = (categoryId: string | number): void => {
		const { categoriesPositions, headerReference } = this.props;
		const categoryScrollTopPosition = categoriesPositions?.[categoryId]?.offsetTop
			- SCROLL_TO_CORRECTION_HEIGHT
			- this.categoriesDynamicBarRef.current.offsetHeight
			- headerReference.current.offsetHeight;
		window.scrollTo({
			top: categoryScrollTopPosition,
		});
	};

	render() {
		return (
			<React.Fragment>
				<CategoriesBar
					categories={this.props.categories}
					categoriesPositions={this.props.categoriesPositions}
					onCategoryClick={this.onCategoryClick}
					categoriesBarRef={this.categoriesStaticBarRef}
				/>
				{!!(this.props.categories.length) && (
					<CategoriesBar
						fixed
						categories={this.props.categories}
						categoriesPositions={this.props.categoriesPositions}
						onCategoryClick={this.onCategoryClick}
						categoriesBarRef={this.categoriesDynamicBarRef}
						isCategoriesBarVisible={this.state.isCategoriesBarVisible}
					/>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	categories: getSortedCategories,
	categoriesPositions: getCategoriesPositions,
	headerReference: getHeaderReference,
});

// @ts-ignore
export const CategoriesBarController = connect(mapStateToProps)(CategoriesBarControllerComponent);
