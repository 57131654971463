import * as React from 'react';
import cn from 'classnames';
import { IBarButtonProps } from '../aline-types';
import styles from './bar-button.module.scss';

export const BarButton = ({
	children,
	className,
	size = 'medium',
	title,
	color,
	border,
	bold,
	onClick,
}: IBarButtonProps) => (
	<div
		onClick={onClick}
		title={String(children) || title}
		className={cn(
			styles['bar-button'],
			className,
			{
				[styles[`btn-${size}`]]: size,
				[styles[`${color}`]]: color,
				[styles[`border-${border}`]]: border,
				[styles['bold']]: bold,
			},
		)}
	>
		{children && (
			<span className={styles.text}>
				{children}
			</span>
		)}
	</div>
);
