import { createSelector } from 'reselect';
import { sortByOrder } from '@/utils';
import { STORE_KEYS } from '@/store/store-constants';
import { TCarouselItems } from './carousel-types';

const getCarouselState = state => state[STORE_KEYS.CAROUSEL];

export const getCarouselItems = createSelector(
	getCarouselState,
	(
		carouselState,
	): TCarouselItems => carouselState.carouselItems,
);

export const getSortedCarouselItems = createSelector(
	getCarouselItems,
	(
		carouselItems: TCarouselItems,
	): TCarouselItems => carouselItems.sort(sortByOrder),
);