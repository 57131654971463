import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { sortByOrder } from '@/utils';
import { INavigationHeader, TNavigationEntity } from './navigation-types';
import styles from './navigation.module.scss';

export const NavigationHeader = React.memo(({ menuItems }: INavigationHeader) => (
	<nav className={styles['navigation']}>
		<ul className={styles['navigation-list']}>
			{!!(menuItems?.length) && menuItems.sort(sortByOrder).map((menuItem: TNavigationEntity) => (
				<li
					key={menuItem.slug}
					className={styles['navigation-list-item']}
					title={menuItem.title}
				>
					<NavLink
						exact
						to={menuItem.slug}
						className={styles['navigation-link']}
						activeClassName={styles['navigation-link-active']}
					>
						{menuItem.title}
					</NavLink>
				</li>
			))}
		</ul>
	</nav>
));