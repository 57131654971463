import * as React from 'react';
import cn from 'classnames';
import { Loader } from '@/components/loader';
import { IButtonProps } from '../aline-types';
import styles from './button.module.scss';

export const Button = ({
	children,
	className,
	size = 'medium',
	title,
	isLoading = false,
	color,
	border,
	bold,
	onClick,
	type,
	disabled,
	loadingText,
}: IButtonProps) => (
	<button
		onClick={onClick}
		title={String(children) || title}
		className={cn(
			styles['btn'],
			className,
			{
				[styles[`btn-${size}`]]: size,
				[styles[`${color}`]]: color,
				[styles[`border-${border}`]]: border,
				[styles['bold']]: bold,
				[styles['hollow-primary']]: type === 'hollow-primary',
				[styles['disabled']]: disabled,
				[styles['btn-loading']]: isLoading,
			},
		)}>
		{isLoading
			? (
				<React.Fragment>
					<Loader />
					<span className={styles['text']}>
						{loadingText}
					</span>
				</React.Fragment>
			)
			: (
				<span className={styles['text']}>
					{children || title}
				</span>
			)
		}
	</button>
);
