import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/modules/aline';
import { ICartEmptyProps } from './cart-empty-types';
import styles from './cart-empty.module.scss';

export const CartEmpty = React.memo(({ buttonRedirectTo, emptyCartAttentionText = '' }: ICartEmptyProps) => (
	<div className={styles['wrapper']}>
		<div className={styles['attention-text']}>
			{emptyCartAttentionText}
		</div>
		<Link
			to={buttonRedirectTo}
			className={styles['button']}
		>
			<Button
				size="small"
				color="black"
				border={3}
				bold
			>
				Перейти в меню
			</Button>
		</Link>
	</div>
));
