import * as React from 'react';
import { Modal } from '@/modules/aline';
import { ProductCard } from '@/components/product-card';
import { IProductModalProps } from './product-modal-types';
import styles from './product-modal.module.scss';

export const ProductModal = React.memo(({
	isOpen,
	closeModal,
	productItem,
	productItemCartCount,
	addToCart,
}: IProductModalProps) => (
	<Modal
		isOpen={isOpen}
		closeModal={closeModal}
		size="giant"
		className={styles['product-card-modal']}
	>
		<ProductCard
			productItem={productItem}
			productItemCartCount={productItemCartCount}
			addToCart={addToCart}
		/>
	</Modal>
));