export enum STORE_KEYS {
	APPLICATION = 'application',
	CAROUSEL = 'carousel',
	CART = 'cart',
	FORM = 'form',
	POLICY_PAGE = 'policy',
	SALES_PAGE = 'sales',
	MODALS = 'modals',
	SIDEBAR = 'sidebar',
}
