import * as React from 'react';
import cn from 'classnames';
import { ReactComponent as VkIcon } from '@/assets/svg/vk.svg';
import { ReactComponent as InstagramIcon } from '@/assets/svg/instagram.svg';
import { ISocialsProps } from './socials-types';
import styles from './socials.module.scss';

export const Socials = React.memo(({ settings }: ISocialsProps) => (
	<div className={styles['socials']}>
		{settings.vk && (
			<div className={cn(
				styles['social'],
				styles['social--vk'],
			)}>
				<a href={settings.vk}>
					<VkIcon />
				</a>
			</div>
		)}
		{settings.instagram && (
			<div className={cn(
				styles['social'],
				styles['social--instagram'],
			)}>
				<a href={settings.instagram}>
					<InstagramIcon />
				</a>
			</div>
		)}
	</div>
));
