import * as React from 'react';
import { Header } from '@/modules/header';
import { ProductCard } from '@/components/product-card';
import { Footer } from '@/modules/footer';
import { Container } from '@/modules/aline';
import { PageWrapper } from '@/components/page-wrapper';
import { IProductPage } from './product-page-types';

export const ProductPage = React.memo(({
	productItem,
	productItemCartCount,
	addToCart,
	proposals,
}: IProductPage) => (
	<React.Fragment>
		<PageWrapper>
			<Header />
			<main>
				<Container>
					<ProductCard
						productItem={productItem}
						productItemCartCount={productItemCartCount}
						addToCart={addToCart}
						proposals={proposals}
					/>
				</Container>
			</main>
		</PageWrapper>
		<Footer />
	</React.Fragment>
));
